// @ts-nocheck
import { ChangeEvent, useRef, useState, useEffect, useMemo } from "react";
import { useParchaApi } from "@/hooks/useParchaApi";
import _ from "lodash";
import {
  AddRounded,
  DeleteForeverRounded,
  PlayCircleFilledRounded,
  UploadFileRounded,
  DriveFileRenameOutlineRounded,
  CancelRounded,
  TipsAndUpdatesOutlined,
} from "@mui/icons-material";
import { AntTab, AntTabList } from "../TailwindComponents/NewAgentChatSSE";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext } from "@mui/lab";
import AddressInput from "../TailwindComponents/AddressInput";
import IndividualForm from "../IndividualForm";
import DocumentValue from "../TailwindComponents/DocumentValue";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";
import AssociatedEntityForm from "../AssociatedEntityForm";
import { LoadScript } from "@react-google-maps/api";
import ParchaLoadingScreen from "../V2_Home_Page/ParchaLoadingScreen";

// Loading Ribbon component for a sleek animation at the top of the form
const LoadingRibbon = () => (
  <div className="relative w-full h-1 overflow-hidden">
    <div 
      className="absolute top-0 h-full w-full bg-gradient-to-r from-purple-300 via-indigo-500 to-purple-300" 
      style={{
        animation: 'loadingWave 1.5s infinite linear',
        backgroundSize: '200% 100%',
      }}
    ></div>
    <style jsx>{`
      @keyframes loadingWave {
        0% {
          background-position: 0% 0;
        }
        100% {
          background-position: 200% 0;
        }
      }
    `}</style>
  </div>
);

const SelfServeForm = ({ agent, selectedEndpoint, onSubmitCallback, onCloseCallback }) => {
  const libraries = ["places"];
  const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_KEY;

  const parchaApi = useParchaApi();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [personaError, setPersonaError] = useState<string | null>(null);
  const [selectedIndividual, setSelectedIndividual] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [normalizedData, setNormalizedData] = useState({ companies: {} });

  useEffect(() => {
    const fetchNormalizedData = async () => {
      try {
        setIsLoadingData(true);
        const dataType = agent.agent_type;
        console.log("Fetching data for type:", dataType);
        const data = await parchaApi.getDemoData(selectedEndpoint?.endpointUrl, dataType);
        console.log("Fetched data:", data);
        setNormalizedData(data);
      } catch (error) {
        console.error("Failed to fetch normalized data:", error);
      } finally {
        setIsLoadingData(false);
      }
    };

    // Check if the environment variable is actually set to "true"
    const allowPrepopulate = import.meta.env.VITE_ALLOW_PRE_POPULATE_DATA === "true";
    console.log("Allow prepopulate:", allowPrepopulate);
    
    if (allowPrepopulate) {
      fetchNormalizedData();
    } else {
      setIsLoadingData(false); // Make sure we're not stuck in loading state
    }
  }, [selectedEndpoint.endpointUrl, agent.agent_type]);

  const allIndividuals = useMemo(() => {
    if (!normalizedData || !normalizedData.kyc) return [];

    return Object.entries(normalizedData.kyc).reduce((acc, [datasetKey, dataset]) => {
      const individual = dataset.self_attested_data;
      if (individual) {
        acc.push({
          firstName: individual.first_name || "",
          middleName: individual.middle_name || "",
          lastName: individual.last_name || "",
          linkedinProfileUrl: individual.linkedin_profile_url || "",
          dateOfBirth: individual.date_of_birth || "",
          sex: individual.sex || "",
          placeOfBirth: individual.place_of_birth || "",
          address: {
            street1: individual.address?.street_1 || "",
            street2: individual.address?.street_2 || "",
            city: individual.address?.city || "",
            state: individual.address?.state || "",
            countryCode: individual.address?.country_code || "",
            postalCode: individual.address?.postal_code || "",
          },
          countryOfNationality: individual.country_of_nationality || "",
          countryOfResidence: individual.country_of_residence || "",
          email: individual.email || "",
          phone: individual.phone || "",
          title: individual.title || "",
          isApplicant: individual.is_applicant || false,
          isBusinessOwner: individual.is_business_owner || false,
          businessOwnershipPercentage: individual.business_ownership_percentage || null,
          proofOfAddressDocs: individual.proof_of_address_documents || [],
          datasetKey,
          displayName: `${individual.first_name} ${individual.last_name} (${datasetKey})`,
          id: datasetKey,
        });
      }
      return acc;
    }, []);
  }, [normalizedData]);

  const kybFormFields = agent?.form_fields?.find((form) => form.type === "kyb");
  const kycFormFields = agent?.form_fields?.find((form) => form.type === "associated_individuals");
  const uboFormFields = agent?.form_fields?.find((form) => form.type === "associated_entities");

  const isThereConfig = ![kybFormFields, kycFormFields, uboFormFields].every(
    (formFields) => typeof formFields === "undefined",
  );

  const [formData, setFormData] = useState({
    businessName: "",
    registeredBusinessName: "",
    website: "",
    businessPurpose: "",
    industry: "",
    tinNumber: "",
    cannabisLicenseNumber: "",
    addressOfIncorporation: {
      street1: "",
      street2: "",
      city: "",
      state: "",
      countryCode: "",
      postalCode: "",
    },
    addressOfOperation: {
      street1: "",
      street2: "",
      city: "",
      state: "",
      countryCode: "",
      postalCode: "",
    },
    businessDescription: "",
    incorporationDate: "",
    sourceOfFunds: [],
    mccCode: "",
    partners: [],
    customers: [],
    incorporationDocs: [],
    businessOwnershipDocs: [],
    proofOfAddressDocs: [],
    promoMarketingDocs: [],
    einDocs: [],
    sourceOfFundsDocs: [],
    cannabisLicenseDocs: [],
    bankCheckDocs: [],
  });

  const [individuals, setIndividuals] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      linkedinProfileUrl: "",
      employerIndustry: "",
      dateOfBirth: "",
      sex: "",
      placeOfBirth: "",
      address: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        countryCode: "",
        postalCode: "",
      },
      countryOfNationality: "",
      countryOfResidence: "",
      email: "",
      phone: "",
      title: "",
      isApplicant: false,
      isBusinessOwner: false,
      businessOwnershipPercentage: null,
      proofOfAddressDocs: [],
    },
  ]);

  const [associatedEntities, setAssociatedEntities] = useState([
    {
      isTrust: false,
      businessName: "",
      registeredBusinessName: "",
      address: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        countryCode: "",
        postalCode: "",
      },
      proofOfAddressDocs: [],
      industry: "",
      tinNumber: null,
      businessOwnership: 0,
      website: "",
      businessDescription: "",
      isBusinessOwner: false,
    },
  ]);

  const [isFileUploadingStatus, setIsFileUploadingStatus] = useState({
    incorporationDocs: false,
    businessOwnershipDocs: false,
    proofOfAddressDocs: false,
    cannabisLicenseDocs: false,
  });

  const [selectedDataset, setSelectedDataset] = useState("Parcha");

  const handleIndividualSelect = (selected) => {
    if (!selected) {
      resetIndividualForm();
      setSelectedIndividual(null);
      return;
    }

    setIndividuals([
      {
        firstName: selected.firstName,
        middleName: selected.middleName || "",
        lastName: selected.lastName,
        linkedinProfileUrl: selected.linkedinProfileUrl || "",
        employerIndustry: selected.employerIndustry || "",
        dateOfBirth: selected.dateOfBirth || "",
        sex: selected.sex || "",
        placeOfBirth: selected.placeOfBirth || "",
        address: {
          street1: selected.address.street1 || "",
          street2: selected.address.street2 || "",
          city: selected.address.city || "",
          state: selected.address.state || "",
          countryCode: selected.address.countryCode || "",
          postalCode: selected.address.postalCode || "",
        },
        countryOfNationality: selected.countryOfNationality || "",
        countryOfResidence: selected.countryOfResidence || "",
        email: selected.email || "",
        phone: selected.phone || "",
        title: selected.title || "",
        isApplicant: true,
        isBusinessOwner: selected.isBusinessOwner || false,
        businessOwnershipPercentage: selected.businessOwnershipPercentage || null,
        proofOfAddressDocs: selected.proofOfAddressDocs || [],
      },
    ]);
  };

  const addIndividual = () => {
    setIndividuals([
      ...individuals,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        linkedinProfileUrl: "",
        employerIndustry: "",
        dateOfBirth: "",
        sex: "",
        placeOfBirth: "",
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          countryCode: "",
          postalCode: "",
        },
        email: "",
        phone: "",
        title: "",
        isApplicant: false,
        isBusinessOwner: false,
        businessOwnershipPercentage: null,
        proofOfAddressDocs: [],
      },
    ]);
  };

  const addAssociatedEntity = () => {
    setAssociatedEntities([
      ...associatedEntities,
      {
        isTrust: false,
        businessName: "",
        registeredBusinessName: "",
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          countryCode: "",
          postalCode: "",
        },
        proofOfAddressDocs: [],
        industry: "",
        tinNumber: "",
        businessOwnership: 0,
        website: "",
        businessDescription: "",
        isBusinessOwner: false,
      },
    ]);
  };

  const updateAssociatedEntity = (index, newEntity) => {
    const updatedEntities = [...associatedEntities];
    updatedEntities[index] = newEntity;
    setAssociatedEntities(updatedEntities);
  };

  const updateIndividual = (index, newIndividual) => {
    const updatedIndividuals = [...individuals];
    updatedIndividuals[index] = newIndividual;
    setIndividuals(updatedIndividuals);
  };

  const deleteIndividual = (index) => {
    const updatedIndividuals = [...individuals];
    updatedIndividuals.splice(index, 1);
    setIndividuals(updatedIndividuals);
  };

  const deleteEntity = (index) => {
    const updatedEntities = [...associatedEntities];
    updatedEntities.splice(index, 1);
    setAssociatedEntities(updatedEntities);
  };

  const [selectedTab, setSelectedTab] = useState(agent && agent.agent_type === "kyb" ? "business" : "individuals");

  const uploadFiles = async (key, files) => {
    setIsFileUploadingStatus((prevStatus) => ({
      ...prevStatus,
      [key]: true,
    }));

    [...files].forEach((file) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = async (loadEvent: Event) => {
          const base64String = loadEvent?.target?.result?.split(",")[1];
          const result = await parchaApi.uploadBase64Document(selectedEndpoint.endpointUrl, base64String, file.name);
          const newFile = { ...result, file_name: file.name, source_type: "file_url" };
          delete newFile.expiration;
          setFormData((prevFormData) => ({
            ...prevFormData,
            [key]: [...prevFormData[key], newFile],
          }));
          setIsFileUploadingStatus((prevStatus) => ({
            ...prevStatus,
            [key]: false,
          }));
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    await uploadFiles(event.target.name, files);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddressOfIncorporationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      addressOfIncorporation: {
        ...prevFormData.addressOfIncorporation,
        [name]: value,
      },
    }));
  };

  const handleAddressOfIncorporationPlaceChange = (updatedAddress) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      addressOfIncorporation: updatedAddress,
    }));
  };

  const handleAddressOfOperationPlaceChange = (updatedAddress) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      addressOfOperation: updatedAddress,
    }));
  };

  const handleAddressOfOperationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      addressOfOperation: {
        ...prevFormData.addressOfOperation,
        [name]: value,
      },
    }));
  };
  const [caseId, setCaseId] = useState("");
  const [allowPersonaCaseId, setAllowPersonaCaseId] = useState(false);

  useEffect(() => {
    const checkPersonaIntegration = async () => {
      const personaKey = await parchaApi.getIntegrationKey(selectedEndpoint?.endpointUrl, "persona");
      setAllowPersonaCaseId(!!personaKey);
    };

    checkPersonaIntegration();
  }, [selectedEndpoint]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsFormSubmitting(true);
    const {
      businessName,
      registeredBusinessName,
      website,
      businessPurpose,
      businessDescription,
      industry,
      tinNumber,
      cannabisLicenseNumber,
      addressOfIncorporation,
      addressOfOperation,
      incorporationDate,
      incorporationDocs,
      businessOwnershipDocs,
      proofOfAddressDocs,
      promoMarketingDocs,
      einDocs,
      sourceOfFundsDocs,
      sourceOfFunds,
      cannabisLicenseDocs,
      bankCheckDocs,
    } = formData;
    const { street1, street2, city, state, countryCode, postalCode } = addressOfIncorporation;
    const {
      street1: street1Op,
      street2: street2Op,
      city: cityOp,
      state: stateOp,
      countryCode: countryCodeOp,
      postalCode: postalCodeOp,
    } = addressOfOperation;

    if (allowPersonaCaseId && selectedTab === "persona" && caseId.length > 0) {
      setIsFormSubmitting(true);
      setPersonaError(null);
      try {
        const data = await parchaApi.enqueuePersonaJob(
          selectedEndpoint.endpointUrl,
          caseId,
          selectedEndpoint.agentKey,
          agent.agent_type,
        );
        setIsFormSubmitting(false);
        if (data?.job_id) {
          onSubmitCallback(data.job_id);
        } else {
          setPersonaError(data?.message || "Failed to enqueue Persona job");
          setIsFormSubmitting(false);
        }
      } catch (error) {
        console.error("Error enqueuing Persona job:", error);
        setPersonaError(error.message);
        setIsFormSubmitting(false);
      }
      return;
    }

    const input =
      agent.agent_type === "kyb"
        ? {
            agent_key: selectedEndpoint.agentKey,
            kyb_schema: {
              id:
                allowPersonaCaseId && caseId
                  ? caseId
                  : `${agent.agent_name}-${businessName}-${new Date().toISOString().split("T")[0]}`
                      .toLowerCase()
                      .replace(/\s/g, "-"),
              self_attested_data: {
                business_name:
                  !kybFormFields || kybFormFields?.fields.includes("business_name") ? businessName : undefined,
                registered_business_name:
                  !kybFormFields || kybFormFields?.fields.includes("registered_business_name")
                    ? registeredBusinessName
                    : undefined,
                address_of_incorporation:
                  !kybFormFields || kybFormFields?.fields.includes("address_of_incorporation")
                    ? {
                        street_1: street1,
                        street_2: street2,
                        city,
                        state,
                        country_code: countryCode,
                        postal_code: postalCode,
                      }
                    : undefined,
                address_of_operation:
                  !kybFormFields || kybFormFields?.fields.includes("address_of_operation")
                    ? {
                        street_1: street1Op,
                        street_2: street2Op,
                        city: cityOp,
                        state: stateOp,
                        country_code: countryCodeOp,
                        postal_code: postalCodeOp,
                      }
                    : undefined,
                incorporation_date:
                  !kybFormFields || kybFormFields?.fields.includes("incorporation_date")
                    ? incorporationDate
                    : undefined,
                website: !kybFormFields || kybFormFields?.fields.includes("website") ? website : undefined,
                business_purpose:
                  !kybFormFields || kybFormFields?.fields.includes("business_purpose") ? businessPurpose : undefined,
                description:
                  !kybFormFields || kybFormFields?.fields.includes("description") ? businessDescription : undefined,
                industry: !kybFormFields || kybFormFields?.fields.includes("industry") ? industry : undefined,
                tin_number: !kybFormFields || kybFormFields?.fields.includes("tin_number") ? tinNumber : undefined,
                cannabis_license_number:
                  agent.agent_key.includes("cannabis") &&
                  (!kybFormFields || kybFormFields?.fields.includes("cannabis_license_number"))
                    ? cannabisLicenseNumber
                    : undefined,
                partners: !kybFormFields || kybFormFields?.fields.includes("partners") ? [] : undefined,
                customers: !kybFormFields || kybFormFields?.fields.includes("customers") ? [] : undefined,
                source_of_funds:
                  !kybFormFields || kybFormFields?.fields.includes("source_of_funds") ? sourceOfFunds : undefined,
                incorporation_documents:
                  !kybFormFields || kybFormFields?.fields.includes("incorporation_documents")
                    ? incorporationDocs
                    : undefined,
                business_ownership_documents:
                  !kybFormFields || kybFormFields?.fields.includes("business_ownership_documents")
                    ? businessOwnershipDocs
                    : undefined,
                promo_marketing_documents:
                  !kybFormFields || kybFormFields?.fields.includes("promo_marketing_documents")
                    ? promoMarketingDocs
                    : undefined,
                proof_of_address_documents:
                  !kybFormFields || kybFormFields?.fields.includes("proof_of_address_documents")
                    ? proofOfAddressDocs
                    : undefined,
                cannabis_license_documents:
                  agent.agent_key.includes("cannabis") &&
                  (!kybFormFields || kybFormFields?.fields.includes("cannabis_license_documents"))
                    ? cannabisLicenseDocs
                    : undefined,
                ein_documents: !kybFormFields || kybFormFields?.fields.includes("ein_documents") ? einDocs : undefined,
                source_of_funds_documents:
                  !kybFormFields || kybFormFields?.fields.includes("source_of_funds_documents")
                    ? sourceOfFundsDocs
                    : undefined,
                bank_check_documents:
                  !kybFormFields || kybFormFields?.fields.includes("bank_check_documents") ? bankCheckDocs : undefined,
              },
              associated_individuals: individuals
                .filter((individual) => individual.firstName !== "" && individual.lastName !== "")
                .map((individual, index) => {
                  return {
                    id: `${individual.firstName.toLowerCase()}-${index + 1}`,
                    self_attested_data: {
                      first_name: individual.firstName,
                      middle_name:
                        !kycFormFields || kycFormFields?.fields?.includes("middle_name")
                          ? individual.middleName
                          : undefined,
                      last_name: individual.lastName,
                      linkedin_profile_url:
                        !kycFormFields || kycFormFields?.fields?.includes("linkedin_profile_url")
                          ? individual.linkedinProfileUrl
                          : undefined,
                      employer_industry:
                        !kycFormFields || kycFormFields?.fields?.includes("employer_industry")
                          ? individual.employerIndustry
                          : undefined,
                      country_of_nationality:
                        !kycFormFields || kycFormFields?.fields?.includes("country_of_nationality")
                          ? individual.countryOfNationality
                          : undefined,
                      date_of_birth:
                        !kycFormFields || kycFormFields?.fields?.includes("date_of_birth")
                          ? individual.dateOfBirth
                          : undefined,
                      address:
                        !kycFormFields || kycFormFields?.fields?.includes("address")
                          ? {
                              street_1: individual.address.street1,
                              street_2: individual.address.street2,
                              city: individual.address.city,
                              state: individual.address.state,
                              country_code: individual.address.countryCode,
                              postal_code: individual.address.postalCode,
                            }
                          : undefined,
                      email: !kycFormFields || kycFormFields?.fields?.includes("email") ? individual.email : undefined,
                      phone: !kycFormFields || kycFormFields?.fields?.includes("phone") ? individual.phone : undefined,
                      title: !kycFormFields || kycFormFields?.fields?.includes("title") ? individual.email : undefined,
                      sex: !kycFormFields || kycFormFields?.fields?.includes("sex") ? individual.sex : undefined,
                      is_applicant:
                        !kycFormFields || kycFormFields?.fields?.includes("is_applicant")
                          ? individual.isApplicant
                          : undefined,
                      is_business_owner:
                        !kycFormFields || kycFormFields?.fields?.includes("is_business_owner")
                          ? individual.isBusinessOwner
                          : undefined,
                      business_ownership_percentage:
                        !kycFormFields || kycFormFields?.fields?.includes("business_ownership_percentage")
                          ? typeof individual.businessOwnershipPercentage === "string"
                            ? null
                            : individual.businessOwnershipPercentage
                          : undefined,
                      proof_of_address_documents:
                        !kycFormFields || kycFormFields?.fields?.includes("proof_of_address_documents")
                          ? individual.proofOfAddressDocs
                          : undefined,
                    },
                  };
                }),
              associated_entities: associatedEntities
                .filter((entity) => entity.businessName !== "" || entity.registeredBusinessName !== "")
                .map((entity, index) => {
                  return {
                    id: `parcha-kyc-test-entity-${index + 1}`,
                    self_attested_data: {
                      business_name:
                        !uboFormFields || uboFormFields?.fields?.includes("business_name")
                          ? entity.businessName
                          : undefined,
                      registered_business_name:
                        !uboFormFields || uboFormFields?.fields?.includes("registered_business_name")
                          ? entity.registeredBusinessName
                          : undefined,
                      address:
                        !uboFormFields || uboFormFields?.fields?.includes("address")
                          ? {
                              street_1: entity.address.street1,
                              street_2: entity.address.street2,
                              city: entity.address.city,
                              state: entity.address.state,
                              country_code: entity.address.countryCode,
                              postal_code: entity.address.postalCode,
                            }
                          : undefined,
                      tin_number:
                        !uboFormFields || uboFormFields?.fields?.includes("tin_number") ? entity.tinNumber : undefined,
                      business_ownership_percentage:
                        !uboFormFields || uboFormFields?.fields?.includes("business_ownership_percentage")
                          ? typeof entity.businessOwnershipPercentage === "string"
                            ? null
                            : entity.businessOwnershipPercentage
                          : undefined,
                      website:
                        !uboFormFields || uboFormFields?.fields?.includes("website") ? entity.website : undefined,
                      country_code:
                        !uboFormFields || uboFormFields?.fields?.includes("country_code")
                          ? entity.address.countryCode
                          : undefined,
                      description:
                        !uboFormFields || uboFormFields?.fields?.includes("description")
                          ? entity.businessDescription
                          : undefined,
                    },
                  };
                }),
            },
          }
        : agent.agent_type === "kyc"
          ? {
              agent_key: selectedEndpoint.agentKey,
              kyc_schema: {
                id: `${individuals[0].firstName.toLowerCase()}-${individuals[0].lastName.toLowerCase()}`,
                self_attested_data: {
                  first_name: individuals[0].firstName,
                  middle_name: individuals[0].middleName,
                  last_name: individuals[0].lastName,
                  linkedin_profile_url: individuals[0].linkedinProfileUrl,
                  employer_industry: individuals[0].employerIndustry,
                  date_of_birth: individuals[0].dateOfBirth,
                  country_of_nationality: individuals[0].countryOfNationality,
                  country_of_residence: individuals[0].countryOfResidence,
                  address: {
                    street_1: individuals[0].address.street1,
                    street_2: individuals[0].address.street2,
                    city: individuals[0].address.city,
                    state: individuals[0].address.state,
                    country_code: individuals[0].address.countryCode,
                    postal_code: individuals[0].address.postalCode,
                  },
                  email: individuals[0].email,
                  phone: individuals[0].phone,
                  title: individuals[0].title,
                  sex: individuals[0].sex,
                  is_applicant: individuals[0].isApplicant,
                  is_business_owner: individuals[0].isBusinessOwner,
                  business_ownership_percentage:
                    typeof individuals[0].businessOwnershipPercentage === "string"
                      ? null
                      : individuals[0].businessOwnershipPercentage,
                  proof_of_address_documents: individuals[0].proofOfAddressDocs,
                },
              },
            }
          : null;

    if (!input) return;
    input.run_in_parallel = true;
    const data = await parchaApi.enqueueAgent(selectedEndpoint.endpointUrl, input, agent.agent_type);
    setIsFormSubmitting(false);
    onSubmitCallback(data.job_id);
  };

  const incorporationDocsInputRef = useRef<HTMLInputElement>(null);
  const bankCheckDocsInputRef = useRef<HTMLInputElement>(null);
  const businessOwnershipDocsInputRef = useRef<HTMLInputElement>(null);
  const proofOfAddressDocsInputRef = useRef<HTMLInputElement>(null);
  const promoMarketingDocsInputRef = useRef<HTMLInputElement>(null);
  const einDocsInputRef = useRef<HTMLInputElement>(null);
  const sourceOfFundsDocsInputRef = useRef<HTMLInputElement>(null);
  const cannabisLicenseDocsInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (!files) return;

    const key = e.currentTarget.querySelector("input")?.name;
    await uploadFiles(key, files);
  };

  const handleIncorporationDocumentsClick = (e) => {
    const currentRef = incorporationDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const handleProofOfAddressDocumentsClick = (e) => {
    const currentRef = proofOfAddressDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const handlePromoMaterialDocumentsClick = (e) => {
    const currentRef = promoMarketingDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const handleBusinessOwnershipDocumentsClick = (e) => {
    const currentRef = businessOwnershipDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const handleEINDocumentsClick = (e) => {
    const currentRef = einDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const handleSourceOfFundsDocumentsClick = (e) => {
    const currentRef = sourceOfFundsDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const handleCannabisLicenseDocumentsClick = (e) => {
    const currentRef = cannabisLicenseDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const handleBankCheckDocumentsClick = (e) => {
    const currentRef = bankCheckDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const shouldRenderTabs =
    (agent.agent_type === "kyb" &&
      ((isThereConfig &&
        [kybFormFields, kycFormFields, uboFormFields].filter((formFields) => formFields && formFields.fields.length > 0)
          .length > 1) ||
        !isThereConfig)) ||
    (agent.agent_type === "kyc" && allowPersonaCaseId);

  const shouldRenderBusinessDetails =
    !kybFormFields ||
    kybFormFields?.fields.includes("incorportation_date") ||
    kybFormFields.fields.includes("tin_number") ||
    kybFormFields.fields.includes("industry") ||
    kybFormFields.fields.includes("website") ||
    kybFormFields.fields.includes("business_purpose") ||
    kybFormFields.fields.includes("description");

  const resetIndividualForm = () => {
    setSelectedIndividual(null);
    setIndividuals([
      {
        firstName: "",
        middleName: "",
        lastName: "",
        linkedinProfileUrl: "",
        employerIndustry: "",
        dateOfBirth: "",
        sex: "",
        placeOfBirth: "",
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          countryCode: "",
          postalCode: "",
        },
        countryOfNationality: "",
        countryOfResidence: "",
        email: "",
        phone: "",
        title: "",
        isApplicant: false,
        isBusinessOwner: false,
        businessOwnershipPercentage: null,
        proofOfAddressDocs: [],
      },
    ]);
  };

  const handleDatasetPopulation = (datasetKey = selectedDataset) => {
    if (!datasetKey) return;

    if (agent.agent_type === "kyb" && normalizedData.kyb[datasetKey]) {
      const kybData = normalizedData.kyb[datasetKey];
      const businessData = kybData.self_attested_data;
      
      setFormData({
        businessName: businessData.business_name || "",
        registeredBusinessName: businessData.registered_business_name || "",
        website: businessData.website || "",
        businessPurpose: businessData.business_purpose || "",
        industry: businessData.industry || "",
        tinNumber: businessData.tin_number || "",
        addressOfIncorporation: {
          street1: businessData.address_of_incorporation?.street_1 || "",
          street2: businessData.address_of_incorporation?.street_2 || "",
          city: businessData.address_of_incorporation?.city || "",
          state: businessData.address_of_incorporation?.state || "",
          countryCode: businessData.address_of_incorporation?.country_code || "",
          postalCode: businessData.address_of_incorporation?.postal_code || "",
        },
        addressOfOperation: {
          street1: businessData.address_of_operation?.street_1 || "",
          street2: businessData.address_of_operation?.street_2 || "",
          city: businessData.address_of_operation?.city || "",
          state: businessData.address_of_operation?.state || "",
          countryCode: businessData.address_of_operation?.country_code || "",
          postalCode: businessData.address_of_operation?.postal_code || "",
        },
        businessDescription: businessData.description || "",
        incorporationDate: businessData.incorporation_date || "",
        sourceOfFunds: businessData.source_of_funds || [],
        incorporationDocs: businessData.incorporation_documents || [],
        businessOwnershipDocs: businessData.business_ownership_documents || [],
        proofOfAddressDocs: businessData.proof_of_address_documents || [],
        einDocs: businessData.ein_documents || [],
        sourceOfFundsDocs: businessData.source_of_funds_documents || [],
        promoMarketingDocs: businessData.promo_marketing_documents || [],
        cannabisLicenseDocs: businessData.cannabis_license_documents || [],
        bankCheckDocs: [],
      });
      
      if (kybData.associated_individuals?.length) {
        setIndividuals(kybData.associated_individuals.map(individual => ({
          firstName: individual.self_attested_data.first_name || "",
          middleName: individual.self_attested_data.middle_name || "",
          lastName: individual.self_attested_data.last_name || "",
          linkedinProfileUrl: individual.self_attested_data.linkedin_profile_url || "",
          employerIndustry: individual.self_attested_data.employer_industry || "",
          dateOfBirth: individual.self_attested_data.date_of_birth || "",
          sex: individual.self_attested_data.sex || "",
          placeOfBirth: individual.self_attested_data.place_of_birth || "",
          address: {
            street1: individual.self_attested_data.address?.street_1 || "",
            street2: individual.self_attested_data.address?.street_2 || "",
            city: individual.self_attested_data.address?.city || "",
            state: individual.self_attested_data.address?.state || "",
            countryCode: individual.self_attested_data.address?.country_code || "",
            postalCode: individual.self_attested_data.address?.postal_code || "",
          },
          countryOfNationality: individual.self_attested_data.country_of_nationality || "",
          countryOfResidence: individual.self_attested_data.country_of_residence || "",
          email: individual.self_attested_data.email || "",
          phone: individual.self_attested_data.phone || "",
          title: individual.self_attested_data.title || "",
          isApplicant: individual.self_attested_data.is_applicant || false,
          isBusinessOwner: individual.self_attested_data.is_business_owner || false,
          businessOwnershipPercentage: individual.self_attested_data.business_ownership_percentage || null,
          proofOfAddressDocs: individual.self_attested_data.proof_of_address_documents || [],
        })));
      }
      
      if (kybData.associated_entities?.length) {
        setAssociatedEntities(kybData.associated_entities.map(entity => ({
          isTrust: entity.self_attested_data.is_trust || false,
          businessName: entity.self_attested_data.business_name || "",
          registeredBusinessName: entity.self_attested_data.registered_business_name || "",
          address: {
            street1: entity.self_attested_data.address?.street_1 || "",
            street2: entity.self_attested_data.address?.street_2 || "",
            city: entity.self_attested_data.address?.city || "",
            state: entity.self_attested_data.address?.state || "",
            countryCode: entity.self_attested_data.address?.country_code || "",
            postalCode: entity.self_attested_data.address?.postal_code || "",
          },
          proofOfAddressDocs: entity.self_attested_data.proof_of_address_documents || [],
          industry: entity.self_attested_data.industry || "",
          tinNumber: entity.self_attested_data.tin_number || "",
          businessOwnershipPercentage: entity.self_attested_data.business_ownership_percentage || 0,
          website: entity.self_attested_data.website || "",
          businessDescription: entity.self_attested_data.description || "",
          isBusinessOwner: entity.self_attested_data.is_business_owner || false,
        })));
      }
    }
  };

  const IndividualSelector = () => {
    // Only show for KYC agents
    if (agent.agent_type !== "kyc") return null;

    const allowPrepopulate = import.meta.env.VITE_ALLOW_PRE_POPULATE_DATA === "true";
    console.log("Individual Selector - Allow prepopulate:", allowPrepopulate, "loading:", isLoadingData);
    
    // If we're not in pre-populate mode, don't show the selector
    if (!allowPrepopulate) return null;
    
    return (
      <div className="flex items-center gap-x-2">
        {!isLoadingData && (
          <select
            value={selectedIndividual?.id || ""}
            onChange={(e) => {
              const selectedId = e.target.value;
              const selected = allIndividuals.find((ind) => ind.id === selectedId);
              setSelectedIndividual(selected);
              // Auto-populate the form when an individual is selected
              if (selected) {
                handleIndividualSelect(selected);
              }
            }}
            className="block w-64 rounded-md border-0 py-1.5 pl-3 pr-10 text-xs text-slate-900 ring-1 ring-inset ring-slate-300 focus:ring-2 focus:ring-brand-purple"
          >
            <option value="">Select an example</option>
            {allIndividuals.map((individual) => (
              <option key={individual.id} value={individual.id}>
                {individual.firstName} {individual.lastName}
              </option>
            ))}
          </select>
        )}
        {selectedIndividual && !isLoadingData && (
          <button
            type="button"
            onClick={resetIndividualForm}
            className="inline-flex items-center px-3 py-1.5 text-xs text-red-600 border border-red-200 rounded-md hover:bg-red-50"
          >
            <CancelRounded sx={{ fontSize: "1rem" }} className="mr-1" />
            Clear
          </button>
        )}
      </div>
    );
  };

  const DatasetSelector = () => {
    // Skip rendering for KYC - we'll use IndividualSelector instead
    if (agent?.agent_type === "kyc") return null;
    
    const allowPrepopulate = import.meta.env.VITE_ALLOW_PRE_POPULATE_DATA === "true";
    console.log("Dataset Selector - Allow prepopulate:", allowPrepopulate, "loading:", isLoadingData);
    
    // If we're not in pre-populate mode, don't show the selector
    if (!allowPrepopulate) return null;
    
    const dataType = agent?.agent_type;
    const datasets = normalizedData[dataType] || {};
    const datasetKeys = Object.keys(datasets);
    
    console.log("Rendering DatasetSelector, datasets:", datasetKeys.length);
    
    return (
      <div className="flex items-center gap-x-2">
        {!isLoadingData && (
          <select
            value={selectedDataset}
            onChange={(e) => {
              const newDataset = e.target.value;
              setSelectedDataset(newDataset);
              // Auto-populate the form when a dataset is selected
              if (newDataset) {
                handleDatasetPopulation(newDataset);
              }
            }}
            className="block w-64 rounded-md border-0 py-1.5 pl-3 pr-10 text-xs text-slate-900 ring-1 ring-inset ring-slate-300 focus:ring-2 focus:ring-brand-purple"
            disabled={datasetKeys.length === 0}
          >
            <option value="">Select an example</option>
            {datasetKeys.map((key) => {
              const data = datasets[key];
              let displayName = key;
              
              if (dataType === "kyb" && data.self_attested_data?.business_name) {
                displayName = data.self_attested_data.business_name;
              } 
              else if (dataType === "kyc" && data.self_attested_data) {
                const firstName = data.self_attested_data.first_name || '';
                const lastName = data.self_attested_data.last_name || '';
                if (firstName || lastName) {
                  displayName = `${firstName} ${lastName}`.trim();
                }
              }
              
              return (
                <option key={key} value={key}>
                  {displayName}
                </option>
              );
            })}
          </select>
        )}
      </div>
    );
  };

  // Demo Selector Header component to contain both selectors
  const DemoSelectorHeader = () => {
    const allowPrepopulate = import.meta.env.VITE_ALLOW_PRE_POPULATE_DATA === "true";
    
    if (!allowPrepopulate) return null;
    
    return (
      <div className="bg-gray-50 border-b border-gray-200 mb-4 pt-2 pb-3">
        <div className="flex items-center justify-between px-5">
          <div className="flex items-center gap-x-3">
            <div className="flex items-center text-amber-600 bg-amber-50 rounded px-2 py-1 text-xs">
              <TipsAndUpdatesOutlined sx={{ fontSize: "0.875rem" }} className="mr-1" />
              <span>Demo Mode</span>
            </div>
            {agent.agent_type === "kyb" ? <DatasetSelector /> : <IndividualSelector />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <LoadScript
      googleMapsApiKey={googleMapsApiKey}
      libraries={libraries}
      loadingElement={
        <div className="p-10 flex items-center justify-center">
          <ParchaLoadingScreen size="small" message="Loading..." />
        </div>
      }
    >
      <form onSubmit={handleSubmit}>
        {isLoadingData && <LoadingRibbon />}
        <DemoSelectorHeader />
        <TabContext value={selectedTab || "individuals"}>
          {shouldRenderTabs ? (
            <AntTabList
              sx={{ width: "100%", justifyContent: "center" }}
              onChange={(_e, value) => setSelectedTab(value)}
            >
              {agent.agent_type === "kyb" && (!kybFormFields || kybFormFields?.fields?.length > 0) ? (
                <AntTab sx={{ width: "100%", flex: "1 1 auto" }} value="business" label="Business" />
              ) : null}
              {!kycFormFields || kycFormFields?.fields?.length > 0 ? (
                <AntTab sx={{ width: "100%", flex: "1 1 auto" }} value="individuals" label="Individuals" />
              ) : null}
              {agent.agent_type === "kyb" && (!uboFormFields || uboFormFields?.fields?.length > 0) ? (
                <AntTab sx={{ width: "100%", flex: "1 1 auto" }} value="entities" label="Associated Entities" />
              ) : null}
              {allowPersonaCaseId && (
                <AntTab sx={{ width: "100%", flex: "1 1 auto" }} value="persona" label="Persona" />
              )}
            </AntTabList>
          ) : null}
          <div className="max-h-[60vh] overflow-y-auto">
            <TabPanel value="business" sx={{ padding: "0", paddingBottom: "1.75rem" }}>
              <div className="space-y-8 px-12">
                <div>
                  <div className="mt-4 flex flex-col gap-y-8">
                    {(!kybFormFields || kybFormFields?.fields.includes("business_name")) && (
                      <div className="rounded-lg">
                        <h4 className="text-base font-semibold text-slate-800 mb-4">Business Name</h4>
                        <div className="space-y-4">
                          <InputWithOverlappingLabel
                            label="Business Name"
                            fieldName="businessName"
                            value={formData.businessName}
                            onChangeHandler={handleChange}
                            required={agent.agent_type === "kyb"}
                          />
                          {(!kybFormFields || kybFormFields?.fields.includes("registered_business_name")) && (
                            <InputWithOverlappingLabel
                              label="Registered Business Name"
                              fieldName="registeredBusinessName"
                              value={formData.registeredBusinessName}
                              onChangeHandler={handleChange}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {shouldRenderBusinessDetails && (
                      <div className="rounded-lg">
                        <h4 className="text-base font-semibold text-slate-800 mb-4">Business Details</h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-4">
                          {(!kybFormFields || kybFormFields?.fields.includes("incorporation_date")) && (
                            <div className="col-span-1">
                              <InputWithOverlappingLabel
                                fieldName="incorporationDate"
                                type="text"
                                label="Incorporation Date"
                                value={formData.incorporationDate}
                                onChange={handleChange}
                                onFocus={(e) => {
                                  e.currentTarget.type = "date";
                                  e.currentTarget.min = "1900-01-01";
                                  e.currentTarget.max = new Date().toISOString().split("T")[0];
                                }}
                                onBlur={(e) => {
                                  e.currentTarget.type = "text";

                                  const selectedDate = new Date(e.target.value);
                                  const minDate = new Date("1900-01-01");
                                  const maxDate = new Date();

                                  if (selectedDate >= minDate && selectedDate <= maxDate) {
                                    return;
                                  }

                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    incorporationDate: "",
                                  }));
                                }}
                              />
                            </div>
                          )}
                          {agent.agent_key.includes("cannabis") &&
                            (!kybFormFields || kybFormFields?.fields.includes("cannabis_license_number")) && (
                              <div className="col-span-1">
                                <InputWithOverlappingLabel
                                  label="Cannabis License"
                                  fieldName="cannabisLicenseNumber"
                                  value={formData.cannabisLicenseNumber}
                                  onChangeHandler={handleChange}
                                />
                              </div>
                            )}
                          {(!kybFormFields || kybFormFields?.fields.includes("tin_number")) && (
                            <div className="col-span-1">
                              <InputWithOverlappingLabel
                                label="TIN"
                                fieldName="tinNumber"
                                value={formData.tinNumber}
                                onChangeHandler={handleChange}
                              />
                            </div>
                          )}
                          {(!kybFormFields || kybFormFields?.fields.includes("industry")) && (
                            <div className="col-span-1">
                              <InputWithOverlappingLabel
                                label="Industry"
                                fieldName="industry"
                                value={formData.industry}
                                onChangeHandler={handleChange}
                              />
                            </div>
                          )}
                          {(!kybFormFields || kybFormFields?.fields.includes("website")) && (
                            <div className="col-span-full">
                              <InputWithOverlappingLabel
                                label="Website"
                                fieldName="website"
                                value={formData.website}
                                onChangeHandler={handleChange}
                              />
                            </div>
                          )}
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-6">
                          {(!kybFormFields || kybFormFields?.fields.includes("business_purpose")) && (
                            <div className="col-span-1">
                              <label
                                htmlFor="businessPurpose"
                                className="block text-xs font-medium leading-6 text-slate-900 mb-1"
                              >
                                Business Purpose
                              </label>
                              <textarea
                                rows={3}
                                name="businessPurpose"
                                id="businessPurpose"
                                onChange={handleChange}
                                className="box-border block w-full rounded-md border-0 py-2 px-3 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple text-xs sm:leading-6"
                                value={formData.businessPurpose}
                              />
                            </div>
                          )}
                          {(!kybFormFields || kybFormFields?.fields.includes("description")) && (
                            <div className="col-span-1">
                              <label
                                htmlFor="businessDescription"
                                className="block text-xs font-medium leading-6 text-slate-900 mb-1"
                              >
                                Business Description
                              </label>
                              <textarea
                                rows={3}
                                name="businessDescription"
                                id="businessDescription"
                                onChange={handleChange}
                                className="box-border block w-full rounded-md border-0 py-2 px-3 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple text-xs sm:leading-6"
                                value={formData.businessDescription}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {(!kybFormFields || kybFormFields?.fields.includes("address_of_operation")) && (
                      <div className="rounded-lg">
                        <h4 className="text-base font-semibold text-slate-800 mb-4">Address of Operation</h4>
                        <AddressInput
                          address={formData.addressOfOperation}
                          onAddressChange={handleAddressOfOperationChange}
                          onPlaceChange={handleAddressOfOperationPlaceChange}
                        />
                      </div>
                    )}

                    {(!kybFormFields || kybFormFields?.fields.includes("address_of_incorporation")) && (
                      <div className="rounded-lg">
                        <h4 className="text-base font-semibold text-slate-800 mb-4">Address of Incorporation</h4>
                        <AddressInput
                          address={formData.addressOfIncorporation}
                          onAddressChange={handleAddressOfIncorporationChange}
                          onPlaceChange={handleAddressOfIncorporationPlaceChange}
                        />
                      </div>
                    )}

                    {(!kybFormFields || kybFormFields?.fields.includes("incorporation_documents")) && (
                      <div className="rounded-lg">
                        <h3 className="text-lg font-semibold text-slate-800 mb-4">Documents</h3>
                        <div className="space-y-6">
                          <div>
                            <h4 className="text-base font-medium text-slate-700 mb-2">Incorporation Documents</h4>
                            <div className="flex flex-col gap-y-2 mb-3 justify-center">
                              {formData.incorporationDocs.map((doc: { file_name?: string; url?: string }) => (
                                <div className="flex items-center gap-x-1" key={doc.url}>
                                  <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                                  <button
                                    onClick={() => {
                                      setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        incorporationDocs: prevFormData.incorporationDocs.filter((d) => d.url !== doc.url),
                                      }));
                                    }}
                                  >
                                    <DeleteForeverRounded
                                      sx={{ fontSize: "2rem", height: "2rem", width: "1rem" }}
                                      className="text-red-600"
                                    />
                                  </button>
                                </div>
                              ))}
                            </div>
                            <div
                              className="border-dashed border-2 border-slate-300 p-4 rounded-md hover:bg-slate-50 transition-colors"
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                            >
                              {isFileUploadingStatus.incorporationDocs ? (
                                <ParchaLoadingScreen message="Uploading Incorporation documents..." size="small" />
                              ) : (
                                <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs py-3">
                                  <UploadFileRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400" />
                                  <p className="text-slate-700">
                                    Drag & Drop or{" "}
                                    <span
                                      className="underline text-brand-purple"
                                      role="button"
                                      onClick={handleIncorporationDocumentsClick}
                                    >
                                      Choose company documents
                                    </span>{" "}
                                    to upload
                                  </p>
                                  <input
                                    type="file"
                                    name="incorporationDocs"
                                    multiple
                                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                                    onChange={handleFileSelect}
                                    ref={incorporationDocsInputRef}
                                    className="hidden"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          
                          {(!kybFormFields || kybFormFields?.fields.includes("business_ownership_documents")) && (
                            <div className="mt-6">
                              <h4 className="text-base font-medium text-slate-700 mb-2">Business Ownership Documents</h4>
                              <div className="flex flex-col gap-y-2 mb-3 justify-center">
                                {formData.businessOwnershipDocs.map((doc: { file_name?: string; url?: string }) => (
                                  <div className="flex items-center gap-x-1" key={doc.url}>
                                    <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                                    <button
                                      onClick={() => {
                                        setFormData((prevFormData) => ({
                                          ...prevFormData,
                                          businessOwnershipDocs: prevFormData.businessOwnershipDocs.filter(
                                            (d) => d.url !== doc.url,
                                          ),
                                        }));
                                      }}
                                    >
                                      <DeleteForeverRounded
                                        sx={{ fontSize: "2rem", height: "2rem", width: "1rem" }}
                                        className="text-red-600"
                                      />
                                    </button>
                                  </div>
                                ))}
                              </div>
                              <div
                                className="border-dashed border-2 border-slate-300 p-4 rounded-md hover:bg-slate-50 transition-colors"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                {isFileUploadingStatus.businessOwnershipDocs ? (
                                  <ParchaLoadingScreen message="Uploading Business Ownership documents..." size="small" />
                                ) : (
                                  <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs py-3">
                                    <UploadFileRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400" />
                                    <p className="text-slate-700">
                                      Drag & Drop or{" "}
                                      <span
                                        className="underline text-brand-purple"
                                        role="button"
                                        onClick={handleBusinessOwnershipDocumentsClick}
                                      >
                                        Choose company documents
                                      </span>{" "}
                                      to upload
                                    </p>
                                    <input
                                      type="file"
                                      id="businessOwnershipDocs"
                                      name="businessOwnershipDocs"
                                      multiple
                                      accept="image/jpg,image/jpeg,image/png,application/pdf"
                                      onChange={handleFileSelect}
                                      ref={businessOwnershipDocsInputRef}
                                      className="hidden"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          
                          {(!kybFormFields || kybFormFields?.fields.includes("proof_of_address_documents")) && (
                            <div className="mt-6">
                              <h4 className="text-base font-medium text-slate-700 mb-2">Proof of Address Documents</h4>
                              <div className="flex flex-col gap-y-2 mb-3 justify-center">
                                {formData.proofOfAddressDocs.map((doc: { file_name?: string; url?: string }) => (
                                  <div className="flex items-center gap-x-1" key={doc.url}>
                                    <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                                    <button
                                      onClick={() => {
                                        setFormData((prevFormData) => ({
                                          ...prevFormData,
                                          proofOfAddressDocs: prevFormData.proofOfAddressDocs.filter(
                                            (d) => d.url !== doc.url,
                                          ),
                                        }));
                                      }}
                                    >
                                      <DeleteForeverRounded
                                        sx={{ fontSize: "2rem", height: "2rem", width: "1rem" }}
                                        className="text-red-600"
                                      />
                                    </button>
                                  </div>
                                ))}
                              </div>
                              <div
                                className="border-dashed border-2 border-slate-300 p-4 rounded-md hover:bg-slate-50 transition-colors"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                {isFileUploadingStatus.proofOfAddressDocs ? (
                                  <ParchaLoadingScreen message="Uploading Proof of Address documents..." size="small" />
                                ) : (
                                  <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs py-3">
                                    <UploadFileRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400" />
                                    <p className="text-slate-700">
                                      Drag & Drop or{" "}
                                      <span
                                        className="underline text-brand-purple"
                                        role="button"
                                        onClick={handleProofOfAddressDocumentsClick}
                                      >
                                        Choose company documents
                                      </span>{" "}
                                      to upload
                                    </p>
                                    <input
                                      type="file"
                                      name="proofOfAddressDocs"
                                      multiple
                                      accept="image/jpg,image/jpeg,image/png,application/pdf"
                                      onChange={handleFileSelect}
                                      ref={proofOfAddressDocsInputRef}
                                      className="hidden"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          
                          {(!kybFormFields || kybFormFields?.fields.includes("ein_documents")) && (
                            <div className="mt-6">
                              <h4 className="text-base font-medium text-slate-700 mb-2">EIN Documents</h4>
                              <div className="flex flex-col gap-y-2 mb-3 justify-center">
                                {formData.einDocs.map((doc: { file_name?: string; url?: string }) => (
                                  <div className="flex items-center gap-x-1" key={doc.url}>
                                    <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                                    <button
                                      onClick={() => {
                                        setFormData((prevFormData) => ({
                                          ...prevFormData,
                                          einDocs: prevFormData.einDocs.filter((d) => d.url !== doc.url),
                                        }));
                                      }}
                                    >
                                      <DeleteForeverRounded
                                        sx={{ fontSize: "2rem", height: "2rem", width: "1rem" }}
                                        className="text-red-600"
                                      />
                                    </button>
                                  </div>
                                ))}
                              </div>
                              <div
                                className="border-dashed border-2 border-slate-300 p-4 rounded-md hover:bg-slate-50 transition-colors"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                {isFileUploadingStatus.proofOfAddressDocs ? (
                                  <ParchaLoadingScreen message="Uploading EIN documents..." size="small" />
                                ) : (
                                  <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs py-3">
                                    <UploadFileRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400" />
                                    <p className="text-slate-700">
                                      Drag & Drop or{" "}
                                      <span
                                        className="underline text-brand-purple"
                                        role="button"
                                        onClick={handleEINDocumentsClick}
                                      >
                                        Choose company documents
                                      </span>{" "}
                                      to upload
                                    </p>
                                    <input
                                      type="file"
                                      name="einDocs"
                                      multiple
                                      accept="image/jpg,image/jpeg,image/png,application/pdf"
                                      onChange={handleFileSelect}
                                      ref={einDocsInputRef}
                                      className="hidden"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          
                          {(!kybFormFields || kybFormFields?.fields.includes("source_of_funds_documents")) && (
                            <div className="mt-6">
                              <h4 className="text-base font-medium text-slate-700 mb-2">Source of Funds Documents</h4>
                              <div className="flex flex-col gap-y-2 mb-3 justify-center">
                                {formData.sourceOfFundsDocs.map((doc: { file_name?: string; url?: string }) => (
                                  <div className="flex items-center gap-x-1" key={doc.url}>
                                    <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                                    <button
                                      onClick={() => {
                                        setFormData((prevFormData) => ({
                                          ...prevFormData,
                                          sourceOfFundsDocs: prevFormData.sourceOfFundsDocs.filter((d) => d.url !== doc.url),
                                        }));
                                      }}
                                    >
                                      <DeleteForeverRounded
                                        sx={{ fontSize: "2rem", height: "2rem", width: "1rem" }}
                                        className="text-red-600"
                                      />
                                    </button>
                                  </div>
                                ))}
                              </div>
                              <div
                                className="border-dashed border-2 border-slate-300 p-4 rounded-md hover:bg-slate-50 transition-colors"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                {isFileUploadingStatus.proofOfAddressDocs ? (
                                  <ParchaLoadingScreen message="Uploading Source of Funds documents..." size="small" />
                                ) : (
                                  <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs py-3">
                                    <UploadFileRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400" />
                                    <p className="text-slate-700">
                                      Drag & Drop or{" "}
                                      <span
                                        className="underline text-brand-purple"
                                        role="button"
                                        onClick={handleSourceOfFundsDocumentsClick}
                                      >
                                        Choose company documents
                                      </span>{" "}
                                      to upload
                                    </p>
                                    <input
                                      type="file"
                                      name="sourceOfFundsDocs"
                                      multiple
                                      accept="image/jpg,image/jpeg,image/png,application/pdf"
                                      onChange={handleFileSelect}
                                      ref={sourceOfFundsDocsInputRef}
                                      className="hidden"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          
                          {kybFormFields?.fields.includes("promo_marketing_documents") && (
                            <div className="mt-6">
                              <h4 className="text-base font-medium text-slate-700 mb-2">Marketing Materials</h4>
                              <div className="flex flex-col gap-y-2 mb-3 justify-center">
                                {formData.promoMarketingDocs.map((doc: { file_name?: string; url?: string }) => (
                                  <div className="flex items-center gap-x-1" key={doc.url}>
                                    <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                                    <button
                                      onClick={() => {
                                        setFormData((prevFormData) => ({
                                          ...prevFormData,
                                          promoMarketingDocs: prevFormData.promoMarketingDocs.filter(
                                            (d) => d.url !== doc.url,
                                          ),
                                        }));
                                      }}
                                    >
                                      <DeleteForeverRounded
                                        sx={{ fontSize: "2rem", height: "2rem", width: "1rem" }}
                                        className="text-red-600"
                                      />
                                    </button>
                                  </div>
                                ))}
                              </div>
                              <div
                                className="border-dashed border-2 border-slate-300 p-4 rounded-md hover:bg-slate-50 transition-colors"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                {isFileUploadingStatus.promoMarketingDocs ? (
                                  <ParchaLoadingScreen message="Uploading Marketing Material documents..." size="small" />
                                ) : (
                                  <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs py-3">
                                    <UploadFileRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400" />
                                    <p className="text-slate-700">
                                      Drag & Drop or{" "}
                                      <span
                                        className="underline text-brand-purple"
                                        role="button"
                                        onClick={handlePromoMaterialDocumentsClick}
                                      >
                                        Choose company documents
                                      </span>{" "}
                                      to upload
                                    </p>
                                    <input
                                      type="file"
                                      name="promoMarketingDocs"
                                      multiple
                                      accept="image/jpg,image/jpeg,image/png,application/pdf"
                                      onChange={handleFileSelect}
                                      ref={promoMarketingDocsInputRef}
                                      className="hidden"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          
                          {kybFormFields?.fields.includes("bank_check_documents") && (
                            <div className="mt-6">
                              <h4 className="text-base font-medium text-slate-700 mb-2">Bank Check Documents</h4>
                              <div className="flex flex-col gap-y-2 mb-3 justify-center">
                                {formData.bankCheckDocs.map((doc: { file_name?: string; url?: string }) => (
                                  <div className="flex items-center gap-x-1" key={doc.url}>
                                    <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                                    <button
                                      onClick={() => {
                                        setFormData((prevFormData) => ({
                                          ...prevFormData,
                                          bankCheckDocs: prevFormData.bankCheckDocs.filter((d) => d.url !== doc.url),
                                        }));
                                      }}
                                    >
                                      <DeleteForeverRounded
                                        sx={{ fontSize: "2rem", height: "2rem", width: "1rem" }}
                                        className="text-red-600"
                                      />
                                    </button>
                                  </div>
                                ))}
                              </div>
                              <div
                                className="border-dashed border-2 border-slate-300 p-4 rounded-md hover:bg-slate-50 transition-colors"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                {isFileUploadingStatus.bankCheckDocs ? (
                                  <ParchaLoadingScreen message="Uploading Bank Check documents..." size="small" />
                                ) : (
                                  <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs py-3">
                                    <UploadFileRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400" />
                                    <p className="text-slate-700">
                                      Drag & Drop or{" "}
                                      <span
                                        className="underline text-brand-purple"
                                        role="button"
                                        onClick={handleBankCheckDocumentsClick}
                                      >
                                        Choose company documents
                                      </span>{" "}
                                      to upload
                                    </p>
                                    <input
                                      type="file"
                                      name="bankCheckDocs"
                                      multiple
                                      accept="image/jpg,image/jpeg,image/png,application/pdf"
                                      onChange={handleFileSelect}
                                      ref={bankCheckDocsInputRef}
                                      className="hidden"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          
                          {agent.agent_key.includes("cannabis") &&
                            (!kybFormFields || kybFormFields?.fields.includes("cannabis_license_documents")) && (
                              <div className="mt-6">
                                <h4 className="text-base font-medium text-slate-700 mb-2">Cannabis License Documents</h4>
                                <div className="flex flex-col gap-y-2 mb-3 justify-center">
                                  {formData.cannabisLicenseDocs.map((doc: { file_name?: string; url?: string }) => (
                                    <div className="flex items-center gap-x-1" key={doc.url}>
                                      <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                                      <button
                                        onClick={() => {
                                          setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            cannabisLicenseDocs: prevFormData.cannabisLicenseDocs.filter(
                                              (d) => d.url !== doc.url,
                                            ),
                                          }));
                                        }}
                                      >
                                        <DeleteForeverRounded
                                          sx={{ fontSize: "2rem", height: "2rem", width: "1rem" }}
                                          className="text-red-600"
                                        />
                                      </button>
                                    </div>
                                  ))}
                                </div>
                                <div
                                  className="border-dashed border-2 border-slate-300 p-4 rounded-md hover:bg-slate-50 transition-colors"
                                  onDragOver={handleDragOver}
                                  onDrop={handleDrop}
                                >
                                  {isFileUploadingStatus.cannabisLicenseDocs ? (
                                    <ParchaLoadingScreen message="Uploading Cannabis License documents..." size="small" />
                                  ) : (
                                    <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs py-3">
                                      <UploadFileRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400" />
                                      <p className="text-slate-700">
                                        Drag & Drop or{" "}
                                        <span
                                          className="underline text-brand-purple"
                                          role="button"
                                          onClick={handleCannabisLicenseDocumentsClick}
                                        >
                                          Choose company documents
                                        </span>{" "}
                                        to upload
                                      </p>
                                      <input
                                        type="file"
                                        name="cannabisLicenseDocs"
                                        multiple
                                        accept="image/jpg,image/jpeg,image/png,application/pdf"
                                        onChange={handleFileSelect}
                                        ref={cannabisLicenseDocsInputRef}
                                        className="hidden"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="individuals" sx={{ padding: "0", paddingBottom: "1.75rem" }}>
                {individuals.map((individual, index) => (
                  <IndividualForm
                    key={index}
                    formFields={kycFormFields}
                    isKYCAgent={agent.agent_type === "kyc"}
                    selectedEndpoint={selectedEndpoint}
                    individual={individual}
                    deleteIndividual={deleteIndividual}
                    updateIndividual={updateIndividual}
                    index={index}
                  />
                ))}
                {agent && agent.agent_type === "kyb" ? (
                  <div className="w-full px-12 flex justify-center mt-6">
                    <button
                      onClick={addIndividual}
                      type="button"
                      className="rounded-md px-4 py-2 text-slate-900 shadow-sm ring ring-2 ring-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                    >
                      <span className="inline-block">
                        <AddRounded sx={{ fontSize: "1rem" }} width="8" height="8" />
                      </span>
                      <span className="font-normal text-xs ml-1"> Add an Invididual</span>
                    </button>
                  </div>
                ) : null}
              </TabPanel>
              <TabPanel value="entities" sx={{ padding: "0", paddingBottom: "1.75rem" }}>
                {associatedEntities.map((entity, index) => (
                  <AssociatedEntityForm
                    key={index}
                    formFields={uboFormFields}
                    selectedEndpoint={selectedEndpoint}
                    deleteEntity={deleteEntity}
                    entity={entity}
                    index={index}
                    updateEntity={updateAssociatedEntity}
                  />
                ))}
                <div className="w-full px-12 flex justify-center mt-6">
                  <button
                    onClick={addAssociatedEntity}
                    type="button"
                    className="rounded-md px-4 py-2 text-slate-900 shadow-sm ring ring-2 ring-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                  >
                    <span className="inline-block">
                      <AddRounded sx={{ fontSize: "1rem" }} width="8" height="8" />
                    </span>
                    <span className="font-normal text-xs ml-1"> Add an Associated Entity</span>
                  </button>
                </div>
              </TabPanel>
              {allowPersonaCaseId && (
                <TabPanel value="persona" sx={{ padding: "0", paddingBottom: "1.75rem" }}>
                  <form>
                    <div className="space-y-8 px-12 mt-6">
                      <InputWithOverlappingLabel
                        label="Persona Case ID"
                        value={caseId}
                        onChange={(e) => setCaseId(e.target.value)}
                      />
                      {personaError && <p className="text-red-600">{personaError}</p>}
                    </div>
                  </form>
                </TabPanel>
              )}
            </div>
          </TabContext>
          <div className="col-span-full flex gap-x-2 justify-end border-t border-slate-100 p-4 text-xs">
            <button
              onClick={onCloseCallback}
              type="button"
              className="inline-flex justify-center items-center py-2 px-4 border border border-solid border-slate-300 shadow-sm font-medium rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
            >
              Cancel
            </button>
            <button
              disabled={isFormSubmitting}
              type="submit"
              className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm rounded-md text-white bg-brand-purple hover:bg-brand-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-purple"
            >
              <PlayCircleFilledRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
              <span className="line-clamp-1">Run Job</span>
            </button>
          </div>
        </form>
      </LoadScript>
    );
  };

  export default SelfServeForm;
