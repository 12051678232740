import { Box, Divider, Typography } from "@mui/material";
import { cloneElement, Fragment, Key, ReactElement, useState } from "react";
import PayloadRenderer from "@parcha-src/components/PayloadRenderer";
import {
  CommandResult,
  FraudVerificationData,
  MessageProps,
  OpencorporatesData,
  OutputSchema,
  SanctionHit,
  ScannedWebsiteWithAnalysis,
  WebRelevanceCheckResult,
} from "@parcha-src/types";
import BaseMessage from "../BaseMessage";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import ReactMarkdown from "react-markdown";
import { getLogoFromAgentKey, getLogoFromCommandId } from "@parcha-src/utils";
import MapView from "@/components/TailwindComponents/MapView";
import AuditLogDocument from "@/components/TailwindComponents/AuditLogDocument";
import WebsiteCard from "@/components/TailwindComponents/WebsiteCard";
import WebsiteList from "@/components/TailwindComponents/WebsiteList";
import BusinessDueDiligenceCard from "@/components/TailwindComponents/BusinessDueDiligenceCheckCard";
import BusinessSelfAttestedDataCard from "@/components/TailwindComponents/BusinessSelfAttestedDataCard";
import IndividualSelfAttestedDataCard from "@/components/TailwindComponents/IndividualSelfAttestedDataCard";
import GovernmentIDCard from "@/components/TailwindComponents/GovernmentIDCard";
import AdverseMediaHit from "@/components/TailwindComponents/AdverseMediaHit";
import KYBProofOfAddressCard from "@/components/TailwindComponents/KYBProofOfAddressCard";
import KYCProofOfAddressCard from "@/components/TailwindComponents/KYCProofOfAddressCard";
import IncorporationDocument from "@/components/TailwindComponents/IncorporationDocument";
import OpenCorporatesCard from "@/components/TailwindComponents/OpenCorporatesCard";
import OwnershipDocument from "@/components/TailwindComponents/OwnershipDocument";
import MCCCard from "@/components/TailwindComponents/MCCCard";
import HighRiskIndustryCard from "@/components/TailwindComponents/HighRiskIndustryCard";
import ProhibitedHighRiskCountryCard from "@/components/TailwindComponents/ProhibitedHighRiskCountryCard";
import BusinessInfoCard from "@/components/TailwindComponents/BusinessInfoCard";
import SanctionsWatchlistCard from "@/components/TailwindComponents/SanctionsWatchlistCard";
import KYCSanctionsWatchlistCard from "@/components/TailwindComponents/KYCSanctionsWatchlistCard";
import PEPCard from "@/components/TailwindComponents/PEPCard";
import TINCheckCard from "@/components/TailwindComponents/TINCheckCard";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import OpenCorporatesOfficersCard from "@/components/TailwindComponents/OpenCorporatesOfficersCard";
import OpenSourceBusinessDueDiligenceCard from "@/components/TailwindComponents/OpenSourceBusinessDueDiligenceCard";
import UDAPDocumentCard from "@/components/TailwindComponents/UDAPDocumentCard";
import AdverseMediaPotentialHitV2 from "@/components/TailwindComponents/AdverseMediaPotentialHitV2";
import RemovedAdverseMediaArticles from "@/components/TailwindComponents/RemovedAdverseMediaArticles";
import PepV2Collection from "@/components/TailwindComponents/PEPV2Collection";
import WebRelevanceResultList from "@/components/TailwindComponents/WebRelevanceResultList";
import WebRelevanceCard from "@/components/TailwindComponents/WebRelevanceCard";
import CannabisRegistrationCard from "@/components/TailwindComponents/CannabisRegistrationCard";
import IncorporationDocumentExtractionCard, {
  KYBIncorporationDocumentsExtractionData,
} from "@/components/TailwindComponents/IncorporationDocumentExtractionCard";
import CannabisLicenseExtractionCard, {
  CannabisLicenseExtractionData,
} from "@/components/TailwindComponents/CannabisLicenseExtractionCard";
const MAX_RENDERED_PAYLOADS = 3;

const CommandResultsUI = ({
  id,
  isParchaUser,
  messageContents,
  hideChatMsg,
  appendToPrev,
  feedbackData,
  caseId,
  endpointUrl,
  handleDocumentValidationClick,
  showAllContent = false,
}: MessageProps) => {
  const [showMore, setShowMore] = useState(false);

  const commandResult = messageContents as CommandResult;
  const commandOutput = commandResult.output as OutputSchema;

  let icon: ReactElement | null = null;
  const props = { color: "#FFFFFF", width: "1.5rem", height: "1.5rem" };

  if (commandResult.command_id) {
    icon = getLogoFromCommandId(commandResult.command_id, props);
  } else if (commandResult.agent_key) {
    icon = getLogoFromAgentKey(commandResult.agent_key, props);
  }

  const getPayloadToRender = (content: CommandResult) => {
    const args = content.args;
    const output = content.output as OutputSchema;
    const payload = output?.payload;
    const didPass = ["true", "pass", "passed", "yes"].includes(output?.passed?.toString().toLowerCase());
    const level = typeof output?.passed === "boolean" ? (didPass ? "success" : "failure") : "normal";

    if (payload?.type) {
      switch (payload.type) {
        case "BusinessDueDiligenceExtractorOutputPayload": {
          const {
            street_1: street1,
            street_2: street2,
            city,
            state,
            country_code: countryCode,
            postal_code: postalCode,
          } = payload.business_address;
          return (
            <BusinessInfoCard
              name={payload.business_name}
              description={payload.business_description}
              address={{
                street1,
                street2,
                city,
                state,
                countryCode,
                postalCode,
              }}
              country={payload.business_country}
              industry={payload.business_industry}
              customers={payload.business_customers}
              partners={payload.business_partners}
              customerCountries={payload.customer_countries}
              candidateMCCCodes={payload.candidate_mcc_codes}
              url={payload.url}
            />
          );
        }
        case "OpenSourceBusinessDueDiligenceCheckResult": {
          return (
            <div className="mt-4">
              {payload.verified_business_name ? (
                <BusinessDueDiligenceCard
                  level={level}
                  name={payload.verified_business_name}
                  description={payload.verified_business_description}
                  address={{
                    street1: payload.verified_business_address.street_1,
                    street2: payload.verified_business_address.street_2,
                    city: payload.verified_business_address.city,
                    state: payload.verified_business_address.state,
                    countryCode: payload.verified_business_address.country_code,
                    postalCode: payload.verified_business_address.postal_code,
                  }}
                />
              ) : (
                <OpenSourceBusinessDueDiligenceCard
                  level={level}
                  name={payload.business_name_match?.name || args.input_data.business_name}
                  description={payload.business_description_match?.description}
                  businessDescriptionMatch={payload.business_description_match?.exact_match ? "exact" : "none"}
                  businessDescriptionMatchExplanation={payload.business_description_match?.explanation}
                  websiteUrl={payload.business_website_match?.website}
                  businessNameMatch={
                    payload.business_name_match?.exact_match
                      ? "exact"
                      : payload.business_name_match?.partial_match
                        ? "soft"
                        : "none"
                  }
                  businessWebsiteMatch={payload.business_website_match?.exact_match ? "exact" : "none"}
                  businessWebsiteMatchExplanation={payload.business_website_match?.explanation}
                  incorporationAddress={{
                    street1: payload.business_incorporation_address_match.address?.street_1 || null,
                    street2: payload.business_incorporation_address_match.address?.street_2 || null,
                    city: payload.business_incorporation_address_match.address?.city || null,
                    state: payload.business_incorporation_address_match.address?.state || null,
                    postalCode: payload.business_incorporation_address_match.address?.postal_code || null,
                    countryCode: payload.business_incorporation_address_match.address?.country_code || null,
                  }}
                  operationAddress={{
                    street1: payload.business_operating_address_match.address?.street_1 || null,
                    street2: payload.business_operating_address_match.address?.street_2 || null,
                    city: payload.business_operating_address_match.address?.city || null,
                    state: payload.business_operating_address_match.address?.state || null,
                    postalCode: payload.business_operating_address_match.address?.postal_code || null,
                    countryCode: payload.business_operating_address_match.address?.country_code || null,
                  }}
                  addressOfOperationMatch={
                    payload.business_operating_address_match?.exact_match
                      ? "exact"
                      : payload.business_operating_address_match?.partial_match
                        ? "soft"
                        : "none"
                  }
                  addressOfIncorporationMatch={
                    payload.address_of_incorporation_match?.exact_match
                      ? "exact"
                      : payload.address_of_incorporation_match?.partial_match
                        ? "soft"
                        : "none"
                  }
                  businessIncorporationAddressMatchExplanation={
                    payload.business_incorporation_address_match?.explanation
                  }
                  businessOperatingAddressMatchExplanation={payload.business_operating_address_match?.explanation}
                  businessNameMatchExplanation={payload.business_name_match?.explanation}
                  businessOwnerMatches={
                    payload.business_owner_matches?.map(
                      (match: { name: string; exact_match: boolean; partial_match: boolean }) => ({
                        name: match.name,
                        match: match.exact_match ? "exact" : match.partial_match ? "soft" : "none",
                      }),
                    ) || []
                  }
                  sources={payload.sources ?? []}
                />
              )}
            </div>
          );
        }
        case "OpenSourceBusinessDueDiligenceCheckResultV2": {
          <div className="mt-4">
            {payload.verified_business_name ? (
              <BusinessDueDiligenceCard
                level={level}
                name={payload.verified_business_name}
                description={payload.verified_business_description}
                address={{
                  street1: payload.verified_business_address.street_1,
                  street2: payload.verified_business_address.street_2,
                  city: payload.verified_business_address.city,
                  state: payload.verified_business_address.state,
                  countryCode: payload.verified_business_address.country_code,
                  postalCode: payload.verified_business_address.postal_code,
                }}
              />
            ) : (
              <OpenSourceBusinessDueDiligenceCard
                level={level}
                name={payload.business_name_match?.name || args.input_data.business_name}
                description={payload.business_description_match?.description}
                businessDescriptionMatchExplanation={payload.business_description_match?.explanation}
                businessDescriptionMatch={
                  payload.business_description_match?.exact_match
                    ? "exact"
                    : payload.business_description_match?.partial_match
                      ? "soft"
                      : "none"
                }
                websiteUrl={payload.business_website_match?.website}
                businessNameMatchExplanation={payload.business_name_match?.explanation}
                businessNameMatch={
                  payload.business_name_match?.exact_match
                    ? "exact"
                    : payload.business_name_match?.partial_match
                      ? "soft"
                      : "none"
                }
                businessWebsiteMatchExplanation={payload.business_website_match?.explanation}
                businessWebsiteMatch={
                  payload.business_website_match?.exact_match
                    ? "exact"
                    : payload.business_website_match?.partial_match
                      ? "soft"
                      : "none"
                }
                incorporationAddress={{
                  street1: payload.business_incorporation_address_match.address?.street_1 || null,
                  street2: payload.business_incorporation_address_match.address?.street_2 || null,
                  city: payload.business_incorporation_address_match.address?.city || null,
                  state: payload.business_incorporation_address_match.address?.state || null,
                  postalCode: payload.business_incorporation_address_match.address?.postal_code || null,
                  countryCode: payload.business_incorporation_address_match.address?.country_code || null,
                }}
                operationAddress={{
                  street1: payload.business_operating_address_match.address?.street_1 || null,
                  street2: payload.business_operating_address_match.address?.street_2 || null,
                  city: payload.business_operating_address_match.address?.city || null,
                  state: payload.business_operating_address_match.address?.state || null,
                  postalCode: payload.business_operating_address_match.address?.postal_code || null,
                  countryCode: payload.business_operating_address_match.address?.country_code || null,
                }}
                businessOperatingAddressMatchExplanation={payload.business_operating_address_match?.explanation}
                addressOfOperationMatch={
                  payload.business_operating_address_match?.exact_match
                    ? "exact"
                    : payload.business_operating_address_match?.partial_match
                      ? "soft"
                      : "none"
                }
                addressOfIncorporationMatch={
                  payload.address_of_incorporation_match?.exact_match
                    ? "exact"
                    : payload.address_of_incorporation_match?.partial_match
                      ? "soft"
                      : "none"
                }
                businessIncorporationAddressMatchExplanation={payload.business_incorporation_address_match?.explanation}
                businessOwnerMatches={
                  payload.business_owner_matches?.map(
                    (match: { name: string; exact_match: boolean; partial_match: boolean }) => ({
                      name: match.name,
                      match: match.exact_match ? "exact" : match.partial_match ? "soft" : "none",
                    }),
                  ) || []
                }
                sources={payload.sources || []}
              />
            )}
          </div>;
        }
        case "Address": {
          const {
            street_1: street1,
            street_2: street2,
            city,
            country_code: countryCode,
            postal_code: postalCode,
            state,
          } = payload;
          return (
            <MapView
              street1={street1 || ""}
              street2={street2 || ""}
              city={city || ""}
              countryCode={countryCode || ""}
              postalCode={postalCode || ""}
              state={state || ""}
            />
          );
        }
        case "ScannedWebsite": {
          return (
            <div className="w-full flex justify-center">
              <WebsiteCard
                websiteUrl={payload.webpage_url}
                websiteLabel={payload.webpage_title || payload.webpage_url}
                screenshotUrl={payload.screenshot_url}
              />
            </div>
          );
        }
        case "WebRelevanceCheckResult": {
          return <WebRelevanceCard payload={payload as WebRelevanceCheckResult} />;
        }
        case "ScannedWebsiteList": {
          return <WebsiteList urls={payload.webpage_urls} />;
        }
        case "Document": {
          const documentItem = payload;
          return (
            <div className="mt-4">
              <AuditLogDocument
                documentName={documentItem.file_name}
                documentURL={`${
                  window.location.protocol
                }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(documentItem.url)}`}
                pageCount={documentItem.num_pages}
              />
            </div>
          );
        }
        case "ScannedWebsitesAnalyses": {
          const results = payload.analyses as ScannedWebsiteWithAnalysis[];
          return <WebRelevanceResultList results={results} />;
        }
        case "SelfAttestedData": {
          return (
            <div className="mt-4">
              <BusinessSelfAttestedDataCard
                caseId={caseId ?? ""}
                endpointUrl={endpointUrl ?? ""}
                level={level}
                isParchaUser={isParchaUser}
                payload={payload}
              />
            </div>
          );
        }
        case "KYCSelfAttestedData": {
          return (
            <div className="mt-4">
              <IndividualSelfAttestedDataCard
                caseId={caseId ?? ""}
                endpointUrl={endpointUrl ?? ""}
                level={level}
                isParchaUser={isParchaUser}
                payload={payload}
              />
            </div>
          );
        }
        case "GovernmentIDCheckResult": {
          const {
            street_1: street1,
            street_2: street2,
            city,
            country_code: countryCode,
            postal_code: postalCode,
            state,
          } = payload.verified_address;
          const { verified_first_name, verified_middle_names, verified_last_name } = payload;
          const name = `${verified_first_name ?? ""}${verified_middle_names ? ` ${verified_middle_names}` : ""}${
            verified_last_name ? ` ${verified_last_name}` : ""
          }`;
          return (
            <GovernmentIDCard
              level={level}
              address={{ street1, street2, city, countryCode, postalCode, state }}
              name={name}
              countryOfResidence={payload.verified_country_of_residence}
              dateOfBirth={payload.verified_date_of_birth}
              evidenceMessage=""
              idImageURL={args.verification_data.id_front_image_url}
              idType={payload.government_id_type}
            />
          );
        }
        case "KYBAdverseMediaScreeningCheckData":
        case "KYCAdverseMediaScreeningCheckData": {
          if (!payload.adverse_media_hits || payload.adverse_media_hits.length === 0) return null;
          return (
            <div className="flex flex-col gap-4 mt-4">
              {payload.adverse_media_hits.map((mediaHit: any, index: number) => (
                <AdverseMediaHit adverseMediaHit={mediaHit} key={`${mediaHit.source_url}${index}`} level={"normal"} />
              ))}
            </div>
          );
        }
        case "KYCAdverseMediaScreeningCheckResult":
        case "KYBAdverseMediaScreeningCheckResult":
        case "AdverseMediaScreeningCheckResult": {
          if (!payload.verified_adverse_media_hits) return null;
          return (
            <div className="flex flex-col gap-4 mt-4">
              {payload.verified_adverse_media_hits.map((mediaHit: any, index: number) => (
                <AdverseMediaHit adverseMediaHit={mediaHit} key={`${mediaHit.source_url}${index}`} level={level} />
              ))}
            </div>
          );
        }
        case "KYCProofOfAddressCheckResult": {
          return (
            <KYCProofOfAddressCard
              address={
                payload.verified_address
                  ? {
                      street1: payload.verified_address.street_1,
                      street2: payload.verified_address.street_2,
                      city: payload.verified_address.city,
                      state: payload.verified_address.state,
                      postalCode: payload.verified_address.postal_code,
                      countryCode: payload.verified_address.country_code,
                    }
                  : {
                      street1: "",
                      street2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                      countryCode: "",
                    }
              }
              level={level}
              name={payload.verified_name}
              proofOfAddressDocuments={args.verification_data?.proof_of_address_documents?.map(
                (data: { document: { file_name: string; url: string }; document_type: string }) => ({
                  fileName: data.document.file_name,
                  url: `${
                    window.location.protocol
                  }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(data.document.url)}`,
                  documentType: data.document_type,
                }),
              )}
              title={""}
            />
          );
        }
        case "ProofOfAddressCheckResult": {
          return (
            <KYBProofOfAddressCard
              businessName={payload.verified_name}
              address={{
                street1: payload.verified_address?.street_1,
                street2: payload.verified_address?.street_2,
                city: payload.verified_address?.city,
                state: payload.verified_address?.state,
                postalCode: payload.verified_address?.postal_code,
                countryCode: payload.verified_address?.country_code,
              }}
              proofOfAddressDocuments={args.verification_data?.proof_of_address_documents?.map(
                (data: { document: { file_name: string; url: string }; document_type: string }) => ({
                  fileName: data.document.file_name,
                  url: `${
                    window.location.protocol
                  }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(data.document.url)}`,
                  documentType: data.document_type,
                }),
              )}
            />
          );
        }
        case "KYBIncorporationDocumentVerificationResult": {
          const incorporationDocuments = [
            ...(payload.verified_incorporation_documents || []),
            ...(payload.valid_documents || []),
            ...(payload.invalid_documents || []),
          ];
          const document = incorporationDocuments.length > 0 ? incorporationDocuments[0] : null;

          return (
            <div className="mt-4">
              <IncorporationDocument
                level={level}
                address={
                  payload.verified_business_address
                    ? {
                        street1: payload.verified_business_address.street_1,
                        street2: payload.verified_business_address.street_2,
                        city: payload.verified_business_address.city,
                        state: payload.verified_business_address.state,
                        postalCode: payload.verified_business_address.postal_code,
                        countryCode: payload.verified_business_address.country_code,
                      }
                    : document?.business_address
                      ? {
                          street1: document.business_address.street_1,
                          street2: document.business_address.street_2,
                          city: document.business_address.city,
                          state: document.business_address.state,
                          postalCode: document.business_address.postal_code,
                          countryCode: document.business_address.country_code,
                        }
                      : {
                          street1: "",
                          street2: "",
                          city: "",
                          state: "",
                          postalCode: "",
                          countryCode: "",
                        }
                }
                companyName={payload.verified_business_name}
                companyNumber={payload.verified_registration_number}
                incorporationDate={payload.verified_incorporation_date || document?.extraction_data?.incorporation_date}
                businessActivity={payload.verified_business_activity}
                incorporationDocuments={[
                  {
                    fileName: document?.document.file_name,
                    url: `${window.location.protocol}//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${document?.document.url}`,
                    summary: document?.summary,
                    fraudVerificationData: document?.fraud_verification_data,
                    incorporationDate: document?.extraction_data?.incorporation_date,
                    registrationDocumentType: document?.registration_document_type,
                    businessRegistrationNumber: document?.registration_number,
                    businessName: document?.business_name,
                    jurisdiction: document?.jurisdiction,
                    incorporationNumber: document?.incorporation_number,
                  },
                ]}
              />
            </div>
          );
        }
        case "KYBBusinessOwnershipVerificationResult": {
          return (
            <div className="mt-4">
              <OwnershipDocument
                businessName={payload.business_name}
                businessOwners={
                  payload.verified_business_owners?.map(
                    (owner: { full_name: string; percentage: number; shares: number }) => ({
                      fullName: owner.full_name,
                      percentage: owner.percentage,
                      shares: owner.shares,
                    }),
                  ) || []
                }
                unverifiedBusinessOwners={
                  payload.unverified_business_owners?.map(
                    (owner: { full_name: string; percentage: number | null; shares: number | null }) => ({
                      fullName: owner.full_name,
                      percentage: owner.percentage,
                      shares: owner.shares,
                    }),
                  ) || []
                }
                ownershipDocuments={args?.verification_data?.documented_ownership?.map(
                  (record: {
                    document: { file_name: string; url: string };
                    fraud_verification_data?: FraudVerificationData;
                  }) => ({
                    fileName: record.document.file_name,
                    url: `${window.location.protocol}//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${record.document.url}`,
                    fraudVerificationData: record.fraud_verification_data,
                  }),
                )}
              />
            </div>
          );
        }
        case "TINFormatCheckResult": {
          return (
            <div className="mt-4">
              <TINCheckCard
                businessName={args?.input_data.business_name}
                tin={args?.input_data.tin_number}
                level={payload.tin_format_test_passed ? "success" : "failure"}
              />
            </div>
          );
        }
        case "HighRiskCountryCheckResult": {
          return payload.verified_country && payload.verified_country !== "" ? (
            <div className="mt-4">
              <ProhibitedHighRiskCountryCard
                level={level}
                countries={[{ name: payload.verified_country, countryCode: payload.verified_country }]}
                evidenceMessage={output.answer}
              />
            </div>
          ) : null;
        }
        case "MCCCodeCheckResult": {
          return (
            <MCCCard
              businessDescription={payload.mcc_code_description}
              businessName={args.input_data.business_name}
              level={didPass ? "success" : "failure"}
              mccCode={payload.mcc_code}
              productsSold={payload.products_sold}
              website={args.input_data.website ?? null}
            />
          );
        }
        case "IndustryActivityCheckResult": {
          return (
            <div className="mt-4">
              <HighRiskIndustryCard
                industry={payload.verified_industry}
                businessActivity={payload.verified_business_activity}
                level={level}
                evidenceMessage={output.explanation}
                potentialHits={undefined}
                hits={undefined}
              />
            </div>
          );
        }
        case "KYCWatchlistSanctionsScreeningCheckData": {
          return (
            <div className="flex flex-col gap-5 mt-5">
              {payload.sanctions_data?.map((sanctionHit: any) => {
                return (
                  <KYCSanctionsWatchlistCard
                    countryCode={sanctionHit.source_id.split("_")[0]}
                    countryOfNationality={sanctionHit.country_of_nationality}
                    dateOfBirth={sanctionHit.date_of_birth}
                    placeOfBirth={sanctionHit.place_of_birth}
                    url={sanctionHit?.urls?.length ? sanctionHit.urls[0] : undefined}
                    sanctionListName={sanctionHit.source_id}
                    matchedNames={[sanctionHit.name]}
                    level={level}
                    sanctionsDetails={sanctionHit.sanction_details}
                  />
                );
              })}
            </div>
          );
        }
        case "KYCWatchlistScreeningCheckResult": {
          return (
            <div className="flex flex-col gap-5 mt-5">
              {payload.verified_sanctions_hits?.map((sanctionHit: any) => {
                return (
                  <KYCSanctionsWatchlistCard
                    countryCode={sanctionHit.matched_lists[0].split("_")[0]}
                    countryOfNationality={null}
                    dateOfBirth={sanctionHit.date_of_birth}
                    placeOfBirth={sanctionHit.place_of_birth}
                    url={sanctionHit.source_url}
                    sanctionListName={sanctionHit.matched_lists[0]}
                    matchedNames={sanctionHit.matched_names}
                    level={level}
                    sanctionsDetails={sanctionHit.details}
                  />
                );
              })}
            </div>
          );
        }
        case "SanctionsWatchlistScreeningCheckResult": {
          return payload.verified_sanctions_hits?.map((sanctionHit: SanctionHit) => {
            return (
              <div key={sanctionHit.description} className="flex flex-col gap-5 mt-5">
                <SanctionsWatchlistCard
                  countryCode={sanctionHit.matched_lists[0].split("_")[0]}
                  url={sanctionHit.source_url}
                  sanctionListName={sanctionHit.matched_lists[0]}
                  matchedNames={sanctionHit.matched_names}
                  level={level}
                  description={sanctionHit.description}
                />
              </div>
            );
          });
        }
        case "PEPScreeningCheckData": {
          return payload.pep_data?.map((sanctionHit: any) => {
            return (
              <div className="flex flex-col gap-5 mt-5">
                {payload.pep_data?.map((sanctionHit: any) => {
                  return (
                    <KYCSanctionsWatchlistCard
                      countryCode={sanctionHit.source_id.split("_")[0]}
                      countryOfNationality={sanctionHit.country_of_nationality}
                      dateOfBirth={sanctionHit.date_of_birth}
                      placeOfBirth={sanctionHit.place_of_birth}
                      url={sanctionHit?.urls?.length ? sanctionHit.urls[0] : undefined}
                      sanctionListName={sanctionHit.source_id}
                      matchedNames={[sanctionHit.name]}
                      level={level}
                      sanctionsDetails={sanctionHit.sanction_details}
                    />
                  );
                })}
              </div>
            );
          });
        }
        case "PEPScreeningCheckResult": {
          return payload.verified_pep_hits?.map((sanctionHit: SanctionHit) => {
            return (
              <div key={sanctionHit.source_url} className="flex flex-col gap-5 mt-5">
                <PEPCard
                  countryCode={sanctionHit.matched_lists[0].split("_")[0]}
                  url={sanctionHit.source_url}
                  sanctionListName={sanctionHit.matched_lists[0]}
                  matchedNames={sanctionHit.matched_names}
                  level={level}
                  description={sanctionHit.description}
                />
              </div>
            );
          });
        }
        case "WebResearchDataCheckData": {
          const websites = payload.searched_webpages?.map((webpage: any) => {
            return webpage.webpage_url;
          });
          return <WebsiteList urls={websites} />;
        }

        case "OpenCorporatesBusinessCheckData": {
          const payloadToRender =
            showMore && payload
              ? payload.open_corporates_search_results
              : payload.open_corporates_search_results.slice(0, MAX_RENDERED_PAYLOADS);
          return (
            <>
              {payloadToRender.map((openCorporateResult: OpencorporatesData) => {
                return (
                  <OpenCorporatesCard
                    key={openCorporateResult.name + openCorporateResult.source}
                    source={openCorporateResult.source}
                    name={openCorporateResult.name}
                    inactive={openCorporateResult.inactive}
                    address={{
                      street1: openCorporateResult.address,
                      street2: null,
                      city: null,
                      state: null,
                      countryCode: null,
                      postalCode: null,
                    }}
                    incorporationDate={openCorporateResult.incorporation_date}
                    openCorporatesUrl={openCorporateResult.opencorporates_url}
                    registryUrl={openCorporateResult.registry_url}
                    level={level}
                    country={null}
                    state={null}
                  />
                );
              })}
              {payload?.open_corporates_search_results.length > MAX_RENDERED_PAYLOADS && (
                <div className="w-full flex justify-center mt-2">
                  <button
                    type="button"
                    className="flex justify-center items-center gap-x-1 rounded-md border-solid border-slate-300 border bg-white px-3 py-3 text-xs font-medium text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                    onClick={() => setShowMore((prev) => !prev)}
                  >
                    {showMore
                      ? "Collapse results"
                      : `Show all ${payload.open_corporates_search_results.length} results`}
                    {showMore ? (
                      <ArrowUpward sx={{ fontSize: "1rem" }} className="h-4 w-4" aria-hidden="true" />
                    ) : (
                      <ArrowDownward sx={{ fontSize: "1rem" }} className="h-4 w-4" aria-hidden="true" />
                    )}
                  </button>
                </div>
              )}
            </>
          );
        }
        case "OpenCorporatesBusinessCheckResult": {
          return payload.open_corporates_hits?.map((openCorporateResult: OpencorporatesData) => {
            return (
              <OpenCorporatesCard
                key={openCorporateResult.name + openCorporateResult.source}
                source={openCorporateResult.source}
                name={openCorporateResult.name}
                inactive={openCorporateResult.inactive}
                address={{
                  street1: openCorporateResult.address,
                  street2: null,
                  city: null,
                  state: null,
                  countryCode: null,
                  postalCode: null,
                }}
                incorporationDate={openCorporateResult.incorporation_date}
                openCorporatesUrl={openCorporateResult.opencorporates_url}
                registryUrl={openCorporateResult.registry_url}
                level={level}
                country={null}
                state={null}
              />
            );
          });
        }
        case "OpenCorporatesOfficersCheckData": {
          if (!payload.open_corporates_officers) return null;

          const payloadToRender =
            showMore && payload
              ? payload.open_corporates_officers_results
              : payload.open_corporates_officers_results.slice(0, MAX_RENDERED_PAYLOADS);
          return (
            <div className="mt-4 flex flex-col gap-y-2">
              {payloadToRender.map((officer: any) => {
                return (
                  <OpenCorporatesOfficersCard
                    key={officer.name}
                    name={officer.name}
                    companies={[
                      {
                        name: officer.open_corporates_company.name,
                        url: officer.open_corporates_company.opencorporates_url,
                        position: [officer.position],
                      },
                    ]}
                    level={level}
                    tag=""
                    tagLevel="normal"
                  />
                );
              })}
              {payload?.open_corporates_officers_results.length > MAX_RENDERED_PAYLOADS && (
                <div className="w-full flex justify-center mt-2">
                  <button
                    type="button"
                    className="flex justify-center items-center gap-x-1 rounded-md border-solid border-slate-300 border bg-white px-3 py-3 text-xs font-medium text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                    onClick={() => setShowMore((prev) => !prev)}
                  >
                    {showMore
                      ? "Collapse results"
                      : `Show all ${payload.open_corporates_officers_results.length} results`}
                    {showMore ? (
                      <ArrowUpward sx={{ fontSize: "1rem" }} className="h-4 w-4" aria-hidden="true" />
                    ) : (
                      <ArrowDownward sx={{ fontSize: "1rem" }} className="h-4 w-4" aria-hidden="true" />
                    )}
                  </button>
                </div>
              )}
            </div>
          );
        }
        case "OpenCorporatesOfficersCheckResult": {
          return (
            <div className="flex flex-col gap-y-2 mt-4">
              {payload.verified_business_owners?.map(
                (owner: {
                  name: string;
                  open_corporates_business: {
                    business_name: string;
                    open_corporates_url: string;
                    positions_held_by_business_owner: string[];
                  }[];
                }) => (
                  <OpenCorporatesOfficersCard
                    key={owner.name}
                    name={owner.name}
                    companies={
                      owner.open_corporates_business.map((business) => ({
                        name: business.business_name,
                        url: business.open_corporates_url,
                        position: business.positions_held_by_business_owner,
                      })) || []
                    }
                    level={level}
                    tag="Matched"
                    tagLevel="success"
                  />
                ),
              )}
            </div>
          );
        }
        case "UDAPWebsiteReviewResults": {
          return (
            <div className="flex flex-col gap-y-2 mt-4">
              <UDAPDocumentCard
                level="normal"
                documentTitle={args.file_name}
                documentUrl={args.url}
                matchingEntries={payload.violations.map((entry: any) => ({
                  triggeredKeyword: entry.triggered_keyword,
                  contextText: entry.context_text,
                  reason: entry.reason,
                  pageNumber: payload.page_number + 1,
                  highlightArea: {
                    triggeredKeyword: entry.triggered_keyword,
                    contextText: entry.context_text,
                    height: (entry.bounding_box?.y_max - entry.bounding_box?.y_min) * 100 + 0.5,
                    width: (entry.bounding_box?.x_max - entry.bounding_box?.x_min) * 100 + 0.75,
                    top: entry.bounding_box?.y_min * 100 - 0.27125,
                    left: entry.bounding_box?.x_min * 100 - 0.4,
                    reason: entry.reason,
                    pageNumber: payload.page_number + 1,
                    pageIndex: payload.page_number,
                  },
                }))}
                pageCount={args.num_pages}
              />
            </div>
          );
        }
        case "KYCAdverseMediaEventScreeningCheckData": {
          return (
            <div className="mt-4 flex flex-col gap-y-3">
              {payload.potential_matches.map((potentialMatch: any, index: number) => {
                return <AdverseMediaPotentialHitV2 key={index} {...potentialMatch} showAllContent={showAllContent} />;
              })}
            </div>
          );
        }
        case "KYCRemovedAdverseMediaArticles": {
          return (
            <div className="mt-4 flex flex-col gap-y-3">
              <RemovedAdverseMediaArticles articles={payload.articles} showAllContent={showAllContent} />
            </div>
          );
        }
        case "PEPScreeningCheckDataV2": {
          return (
            <div className="mt-4 flex flex-col gap-y-3 w-full">
              <PepV2Collection pepProfiles={payload.pep_data} showAllPepsDefault={showMore} />
            </div>
          );
        }
        case "RemovedPEPs": {
          return (
            <div className="mt-4 flex flex-col gap-y-3 w-full">
              <PepV2Collection pepProfiles={payload.pep_data} showAllPepsDefault={showMore} />
            </div>
          );
        }
        case "CannabisLicenseExtractionData": {
          return (
            <div className="mt-4">
              <CannabisLicenseExtractionCard
                data={payload as CannabisLicenseExtractionData}
                handleDocumentValidationClick={handleDocumentValidationClick}
                checkResult={commandResult}
              />
            </div>
          );
        }
        case "CannabisLicenseCheckData": {
          if (!payload.cannabis_license_results?.length) {
            return null;
          }
          return (
            <div className="flex flex-col gap-y-4">
              {payload.cannabis_license_results.map(
                (
                  hit: {
                    business_name_match: any;
                    state_match: any;
                    country_match: any;
                    is_active: any;
                    business_name: string;
                    license_id: string;
                    cnb_id: string | null;
                    status: string;
                    activities: any;
                    issued_date: string | null;
                    expiration_date: string | null;
                    last_updated: string | null;
                    address: {
                      street1: string | null;
                      city: string | null;
                      state: string | null;
                      postalCode: string | null;
                      countryCode: string | null;
                      county?: string | null;
                    } | null;
                    cnb_url: string | null;
                    company_name: string | null;
                    company_id: string | null;
                    country: string | null;
                    state: string | null;
                  },
                  index: Key | null | undefined,
                ) => (
                  <CannabisRegistrationCard
                    key={index}
                    level={
                      hit.business_name_match && hit.state_match && hit.country_match && hit.is_active
                        ? "success"
                        : "failure"
                    }
                    name={hit.business_name}
                    licenseId={hit.license_id}
                    cnbId={hit.cnb_id}
                    status={hit.status}
                    activities={hit.activities || []}
                    issuedDate={hit.issued_date}
                    expirationDate={hit.expiration_date}
                    lastUpdated={hit.last_updated}
                    address={hit.address}
                    cnbUrl={hit.cnb_url}
                    companyName={hit.company_name}
                    companyId={hit.company_id}
                    country={hit.country}
                    state={hit.state}
                  />
                ),
              )}
            </div>
          );
        }
        case "KYBIncorporationDocumentsExtractionData": {
          return (
            <div className="mt-4">
              <IncorporationDocumentExtractionCard
                data={payload as KYBIncorporationDocumentsExtractionData}
                handleDocumentValidationClick={handleDocumentValidationClick}
                checkResult={commandResult}
              />
            </div>
          );
        }
        default: {
          return null;
        }
      }
    }

    return (
      payload &&
      Object.entries(payload).map(([key, value], index) => {
        if (Array.isArray(value) && !value.length) return null;
        if (
          typeof value === "object" &&
          value !== null &&
          Object.entries(value).every(([k, v]) => k === "type" || v === null)
        )
          return null;

        const newKey = key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

        return (
          <Fragment key={index}>
            {value && value !== "" ? (
              <>
                <Divider orientation="horizontal" flexItem sx={{ borderColor: "transparent", marginTop: "8px" }} />
                <Typography className="text-slate-900" style={{ fontWeight: "600" }}>{`${newKey}: `}</Typography>
                <PayloadRenderer name={key} value={value} caseId={caseId ?? ""} endpointUrl={endpointUrl ?? ""} />
              </>
            ) : null}
          </Fragment>
        );
      })
    );
  };

  const payloadToRender = commandResult ? getPayloadToRender(commandResult) : null;

  const customComponent = commandOutput && (
    <Box display={"flex"} flexDirection={"column"} marginLeft={"18.5px"} width="100%">
      <Box marginLeft="-0.5rem" marginBottom="0.25rem">
        {icon !== null ? (
          cloneElement(icon, { style: { color: "#5D5FEF", height: "1.25rem", width: "1.25rem" } }, null)
        ) : (
          <NextWeekIcon style={{ color: "#5D5FEF" }} />
        )}
      </Box>

      {commandOutput.explanation && (
        <Box
          borderLeft={payloadToRender ? "0.125rem solid #5D5FEF" : "none"}
          paddingLeft="34px"
          paddingTop="12px"
          paddingBottom={payloadToRender ? "12px" : "0px"}
          sx={{ overflowWrap: "break-word" }}
        >
          <div className="relative z-20 text-slate-700 text-xs leading-tight -mt-[36px]">
            <ReactMarkdown>{commandOutput.explanation}</ReactMarkdown>
          </div>
          {payloadToRender}
        </Box>
      )}
    </Box>
  );

  return (
    <BaseMessage
      id={id}
      icon={!hideChatMsg && !appendToPrev && icon}
      message={!hideChatMsg ? commandOutput.answer : undefined}
      customComponent={customComponent}
    />
  );
};

export default CommandResultsUI;
