import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { DocumentIcon, ShieldCheckIcon, ShieldExclamationIcon } from "@heroicons/react/24/outline";
import DocumentValue from "./DocumentValue";
import InlineCollapsibleText from "./InlineCollapsibleText";
import { WarningRounded } from "@mui/icons-material";

interface Address {
  street1: string | null;
  street2: string | null;
  city: string | null;
  state: string | null;
  countryCode: string | null;
  postalCode: string | null;
}

interface VisualInspectionDetail {
  page: number;
  present: boolean;
  valid: boolean;
  matches_reference: boolean | null;
  confidence: number;
  visual_description: string;
  visual_description_reference: string;
  inspection_summary: string;
}

interface VisualInspection {
  official_seal: VisualInspectionDetail;
  official_signature: VisualInspectionDetail;
  inspection_passed: boolean;
}

interface ExtractionData {
  is_valid_document: boolean;
  analysis: string;
  summary: string;
  date: string | null;
  alerts: Record<string, unknown> | null;
  license_number: string;
  business_name: string;
  expiration_date: string;
  license_facility_address: Address;
  document_type: string;
}

interface CannabisLicenseDocument {
  extraction_data: ExtractionData;
  fraud_verification_data: {
    verification_passed: boolean;
    verification_result: string;
    verification_data: Array<{
      type: string;
      category: string;
      title: string;
      description: string;
    }>;
    verification_description: string;
  } | null;
  visual_inspection: VisualInspection;
  document: {
    url: string;
    file_name: string;
    description: string | null;
    source_type: string;
    num_pages: number | null;
  };
}

export interface CannabisLicenseExtractionData {
  type: "CannabisLicenseExtractionData";
  valid_license_documents: CannabisLicenseDocument[];
  invalid_documents: CannabisLicenseDocument[];
}

export interface CannabisLicenseExtractionCardProps {
  data: CannabisLicenseExtractionData;
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
}

const AddressDisplay: React.FC<{ address: Address; label: string }> = ({ address, label }) => {
  if (!address) return null;

  const hasAddressData = Object.values(address).some((value) => value !== null);
  if (!hasAddressData) return null;

  return (
    <div className="mt-2">
      <span className="font-semibold">{label}:</span>
      <div className="mt-1 text-slate-700">
        {address.street1 && <div>{address.street1}</div>}
        {address.street2 && <div>{address.street2}</div>}
        <div>{[address.city, address.state, address.postalCode].filter(Boolean).join(", ")}</div>
        {address.countryCode && <div>{address.countryCode}</div>}
      </div>
    </div>
  );
};

const VisualInspectionDetail: React.FC<{ detail: VisualInspectionDetail; title: string }> = ({ detail, title }) => {
  return (
    <div className="mt-4">
      <h4 className="font-semibold text-slate-900">{title}</h4>
      <div className="mt-2 space-y-2">
        <div className="flex items-center">
          {detail.valid ? (
            <CheckCircleIcon className="h-4 w-4 text-green-600 mr-2" />
          ) : (
            <XCircleIcon className="h-4 w-4 text-red-600 mr-2" />
          )}
          <span className="text-slate-700">
            {detail.present ? "Present" : "Not present"} (Page {detail.page + 1})
          </span>
        </div>
        <div className="text-slate-600">{detail.inspection_summary}</div>
      </div>
    </div>
  );
};

const DocumentSection: React.FC<{
  document: CannabisLicenseDocument;
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
}> = ({ document, handleDocumentValidationClick, checkResult }) => {
  const { extraction_data, visual_inspection, fraud_verification_data } = document;

  return (
    <div className="text-xs bg-white rounded-lg border border-slate-200/75 shadow-[0_1px_3px_0_rgb(0,0,0,0.05)] p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="min-w-0">
            <p className="font-semibold truncate">{extraction_data.business_name}</p>
            <DocumentValue
              fileName={document.document.file_name}
              url={document.document.url}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={checkResult}
            />
          </div>
        </div>
        {extraction_data.is_valid_document ? (
          <ShieldCheckIcon className="h-5 w-5 text-green-700 flex-shrink-0" />
        ) : (
          <ShieldExclamationIcon className="h-5 w-5 text-red-700 flex-shrink-0" />
        )}
      </div>

      <div className="mt-6">
        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
          <div>
            <span className="font-semibold">License Number</span>
            <p className="mt-1 text-slate-700">{extraction_data.license_number}</p>
          </div>
          <div>
            <span className="font-semibold">Document Type</span>
            <p className="mt-1 text-slate-700">{extraction_data.document_type}</p>
          </div>
          <div>
            <span className="font-semibold">Expiration Date</span>
            <p className="mt-1 text-slate-700">{extraction_data.expiration_date}</p>
          </div>
        </div>

        <AddressDisplay address={extraction_data.license_facility_address} label="Facility Address" />
      </div>

      <div className="mt-6 pt-6 border-t border-slate-100">
        <h4 className="font-semibold text-slate-900">Document Analysis</h4>
        <div className="mt-2 space-y-2 text-slate-700">
          <p>{extraction_data?.analysis}</p>
          <p>{extraction_data?.summary}</p>
        </div>
      </div>

      {fraud_verification_data && (
        <div className="mt-6">
          {fraud_verification_data.verification_data
            .filter((entry) => entry.type === "RISK")
            .map((entry, index) => (
              <div key={index} className="flex items-start gap-2 py-2 first:pt-0">
                <WarningRounded className="text-yellow-600 flex-shrink-0" sx={{ fontSize: "1rem" }} />
                <p className="font-semibold text-yellow-900">{entry.title}</p>
                <p className="text-yellow-700">{entry.description}</p>
              </div>
            ))}
          {fraud_verification_data.verification_description && (
            <p className="mt-2 text-red-600">{fraud_verification_data.verification_description}</p>
          )}
        </div>
      )}

      {visual_inspection && (
        <div className="mt-4 border-t border-slate-200 pt-4">
          <h4 className="font-semibold text-slate-900">Visual Inspection</h4>
          {visual_inspection.official_seal && (
            <VisualInspectionDetail detail={visual_inspection.official_seal} title="Official Seal" />
          )}
          {visual_inspection.official_signature && (
            <VisualInspectionDetail detail={visual_inspection.official_signature} title="Official Signature" />
          )}
        </div>
      )}
    </div>
  );
};

export const CannabisLicenseExtractionCard: React.FC<CannabisLicenseExtractionCardProps> = ({
  data,
  handleDocumentValidationClick,
  checkResult,
}) => {
  return (
    <div className="space-y-6">
      {data.valid_license_documents.map((doc, index) => (
        <DocumentSection
          key={`valid-${index}`}
          document={doc}
          handleDocumentValidationClick={handleDocumentValidationClick}
          checkResult={checkResult}
        />
      ))}

      {data.invalid_documents.map((doc, index) => (
        <DocumentSection
          key={`invalid-${index}`}
          document={doc}
          handleDocumentValidationClick={handleDocumentValidationClick}
          checkResult={checkResult}
        />
      ))}
    </div>
  );
};

export default CannabisLicenseExtractionCard;
